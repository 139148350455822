import '@fontsource/heebo/400.css';
import '@fontsource/heebo/500.css';
import '@fontsource/heebo/600.css';
import '@fontsource/heebo/700.css';
import React from 'react';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import { Login } from './auth/Login';
import { PasswordRecovery } from './auth/PasswordRecovery';
import { ResetPassword } from './auth/ResetPassword';
import { CustomLayout } from './components/layout/Layout';
import { ProtectedRoute } from './components/shared/ProtectedRoutes';
import DividerIcon from './components/shared/icons/DividerIcon';
import PumpIcon from './components/shared/icons/PumpIcon';
import TestIcon from './components/shared/icons/TestIcon';
import UsersIcon from './components/shared/icons/UsersIcon';
import {
  DIAMETERS_INDEX_URL,
  EFFICIENCY_TESTS_INDEX_URL,
  LOGIN_URL,
  PUMPS_INDEX_URL,
  RECOVERY_PASSWORD_URL,
  USERS_INDEX_URL
} from './core/constants/url.constants';
import { PrivilegeType } from './core/models/privileges/privilegeType';
import authProvider from './core/providers/authProvider';
import { dataProvider } from './core/providers/dataProvider';
import { i18nProvider } from './core/providers/i18nProvider';
import jwtManager from './core/providers/jwtManager';
import { DiametersCreate } from './dashboard/diameters/diametersCreate/DiametersCreate';
import { DiametersFlowsShow } from './dashboard/diameters/diametersFlows/diametersFlowsShow/DiametersFlowsShow';
import { DiametersList } from './dashboard/diameters/diametersList/DiametersList';
import { EfficiencyTestsList } from './dashboard/efficiencyTests/efficiencyTestList/EfficiencyTestsList';
import { EfficiencyTestOverview } from './dashboard/efficiencyTests/efficiencyTestOverview/EfficiencyTestOverview';
import { EfficiencyTestsCreate } from './dashboard/efficiencyTests/efficiencyTestsCreate/EfficiencyTestsCreate';
import { PumpsCreate } from './dashboard/pumps/pumpsCreate/PumpsCreate';
import { PumpsList } from './dashboard/pumps/pumpsList/PumpsList';
import { PumpsSpeedCreate } from './dashboard/pumps/pumpsSpeeds/pumpsSpeedCreate/PumpsSpeedCreate';
import { PumpsSpeedList } from './dashboard/pumps/pumpsSpeeds/pumpsSpeedList/PumpsSpeedList';
import { PumpsSpeedShow } from './dashboard/pumps/pumpsSpeeds/pumpsSpeedShow/PumpsSpeedShow';
import { UsersCreate } from './dashboard/users/usersCreate/UsersCreate';
import { UsersEdit } from './dashboard/users/usersEdit/UsersEdit';
import { UsersList } from './dashboard/users/usersList/UsersList';
import { theme } from './style/theme';

const apiUrl = process.env.REACT_APP_API_URL;
jwtManager.setRefreshTokenEndpoint(`${apiUrl}/auth/token`);

const {
  VIEW_EFFICIENCY_TEST,
  MANAGE_EFFICIENCY_TEST,
  VIEW_DIAMETERS,
  MANAGE_DIAMETERS,
  VIEW_PUMPS,
  MANAGE_PUMPS,
  VIEW_USERS,
  MANAGE_USERS
} = PrivilegeType;

const App = () => (
  <Admin
    authProvider={authProvider}
    dataProvider={dataProvider}
    loginPage={Login}
    theme={theme}
    layout={CustomLayout}
    i18nProvider={i18nProvider}
  >
    <CustomRoutes noLayout>
      <Route path={RECOVERY_PASSWORD_URL} element={<PasswordRecovery />} />
      <Route path="/reset-password/:id" element={<ResetPassword />} />
    </CustomRoutes>

    <CustomRoutes>
      {/* Diameter */}
      <Route
        path="/diameters/create"
        element={
          <ProtectedRoute
            privileges={[MANAGE_DIAMETERS]}
            redirectPath={DIAMETERS_INDEX_URL}
          >
            <DiametersCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/diameters/:diameterId"
        element={
          <ProtectedRoute
            privileges={[VIEW_DIAMETERS, MANAGE_DIAMETERS]}
            redirectPath={DIAMETERS_INDEX_URL}
          >
            <DiametersFlowsShow />
          </ProtectedRoute>
        }
      />

      {/* Pumps */}
      <Route
        path="/pumps/create"
        element={
          <ProtectedRoute
            privileges={[MANAGE_PUMPS]}
            redirectPath={PUMPS_INDEX_URL}
          >
            <PumpsCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pumps/:pumpId"
        element={
          <ProtectedRoute
            privileges={[VIEW_PUMPS, MANAGE_PUMPS]}
            redirectPath={PUMPS_INDEX_URL}
          >
            <PumpsSpeedList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pumps/:pumpId/speeds/:speedId"
        element={
          <ProtectedRoute
            privileges={[VIEW_PUMPS, MANAGE_PUMPS]}
            redirectPath={PUMPS_INDEX_URL}
          >
            <PumpsSpeedShow />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pumps/:pumpId/speeds/create"
        element={
          <ProtectedRoute
            privileges={[MANAGE_PUMPS]}
            redirectPath={PUMPS_INDEX_URL}
          >
            <PumpsSpeedCreate />
          </ProtectedRoute>
        }
      />

      {/* Users */}
      <Route
        path="/users/create"
        element={
          <ProtectedRoute
            privileges={[MANAGE_USERS]}
            redirectPath={USERS_INDEX_URL}
          >
            <UsersCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/users/:userId"
        element={
          <ProtectedRoute
            privileges={[VIEW_USERS, MANAGE_USERS]}
            redirectPath={USERS_INDEX_URL}
          >
            <UsersEdit />
          </ProtectedRoute>
        }
      />

      <Route
        path="/efficiency-tests/create"
        element={
          <ProtectedRoute
            privileges={[MANAGE_EFFICIENCY_TEST]}
            redirectPath={EFFICIENCY_TESTS_INDEX_URL}
          >
            <EfficiencyTestsCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/efficiency-tests/:efficiencyTestId"
        element={
          <ProtectedRoute
            privileges={[VIEW_EFFICIENCY_TEST, MANAGE_EFFICIENCY_TEST]}
            redirectPath={EFFICIENCY_TESTS_INDEX_URL}
          >
            <EfficiencyTestOverview />
          </ProtectedRoute>
        }
      />
    </CustomRoutes>

    <Resource
      name="efficiency-tests"
      icon={TestIcon}
      list={
        <ProtectedRoute
          privileges={[VIEW_EFFICIENCY_TEST, MANAGE_EFFICIENCY_TEST]}
          redirectPath={DIAMETERS_INDEX_URL}
        >
          <EfficiencyTestsList />
        </ProtectedRoute>
      }
    />
    <Resource
      name="diameters"
      icon={DividerIcon}
      list={
        <ProtectedRoute
          privileges={[VIEW_DIAMETERS, MANAGE_DIAMETERS]}
          redirectPath={PUMPS_INDEX_URL}
        >
          <DiametersList />
        </ProtectedRoute>
      }
    />
    <Resource
      name="pumps"
      icon={PumpIcon}
      list={
        <ProtectedRoute
          privileges={[VIEW_PUMPS, MANAGE_PUMPS]}
          redirectPath={USERS_INDEX_URL}
        >
          <PumpsList />
        </ProtectedRoute>
      }
    />
    <Resource
      name="users"
      icon={UsersIcon}
      list={
        <ProtectedRoute
          privileges={[VIEW_USERS, MANAGE_USERS]}
          redirectPath={LOGIN_URL}
        >
          <UsersList />
        </ProtectedRoute>
      }
    />
  </Admin>
);
export default App;
