import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function PumpIcon(props: any) {
  const propsMerged = {
    ...props,
    //viewPort: '20 20',
    viewBox: '-1 -3 23 23'
  };
  return (
    <SvgIcon {...propsMerged}>
      <path d="M12.28 6.518v6.975h1V6.518zm-2.514 0v6.975h1V6.518zm-2.514 0v6.975h1V6.518zM20.768 10h-4.032V8.5h4.032zM15.23 1.44h4v.494h-4zM5.17 12.503c0 .811-.66 1.497-1.475 1.497-.815 0-1.475-.686-1.475-1.497 0-.81.66-1.503 1.475-1.503.815 0 1.476.692 1.476 1.503zm.06-10.569h-4V1.44h4zM2.212 14.516c.415.306.928.484 1.483.484.556 0 1.069-.178 1.484-.484v.962c0 .281-.179.522-.509.522H2.72a.52.52 0 0 1-.508-.522zM4.246 2.934v1.938c-.316.275-.516.68-.516 1.128v.518h-.998c-.242 0-.496-.25-.496-.518V2.934zM19.18 12.7c-.107.71-.72 1.301-1.459 1.301-.814 0-1.475-.707-1.475-1.522 0-.814.66-1.478 1.475-1.478.795 0 1.443.632 1.474 1.42h-.015zm-2.967 1.817c.415.306.928.484 1.483.484.556 0 1.069-.178 1.484-.484v.962c0 .281-.179.522-.509.522h-1.95a.52.52 0 0 1-.508-.522zM12.758 4.509h-5.02V3c0-.227.262-.451.5-.451h4.02c.276 0 .5.224.5.451zM15.695 11c-.298.4-.48.889-.498 1.42h-.002v2.085h-9v-2.086h-.001a2.5 2.5 0 0 0-1.464-2.192V6c0-.272.27-.495.5-.495h1.48v.004h6.975v-.004h1.51a.5.5 0 0 1 .5.495zm.54-6.076v-1.99h1.511V6c0 .268-.254.518-.496.518h-.555V6l-.007-.14a1.496 1.496 0 0 0-.452-.936zm-1.04 10.554c0 .842.683 1.522 1.525 1.522h1.95c.843 0 1.525-.68 1.525-1.522V12.42h-.001A2.433 2.433 0 0 0 19.693 11h1.075a1 1 0 0 0 1-1V8.5a1 1 0 0 0-1-1h-3.297c.696-.114 1.275-.752 1.275-1.5V2.934h.484a1 1 0 0 0 1-1V1.44a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v.494a1 1 0 0 0 1 1h.006v1.571h-.04l-1.438-.001V3c0-.78-.672-1.451-1.5-1.451h-4.02l-.144.007C7.334 1.628 6.74 2.269 6.74 3l-.001 1.504H5.246v-1.57a1 1 0 0 0 .984-1V1.44a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v.494a1 1 0 0 0 1 1h.006V6c0 .825.706 1.518 1.496 1.518h.998V10h-.035a2.503 2.503 0 0 0-2.498 2.42h-.002v3.058c0 .842.683 1.522 1.525 1.522h1.95c.837 0 1.516-.67 1.525-1.505v.01h9z" />
    </SvgIcon>
  );
}
