import { AxiosInstance } from 'axios';
import jwtManager from '../../../providers/jwtManager';
import { getDefaultAnonymousRequestConfig } from '../axios.client';

export function getError(
  axiosInstance: AxiosInstance,
  error: { response?: any; config: any }
) {
  const { config } = error;

  if (error.response && error.response.status === 401) {
    if (!!!error.config.headers.HEADER_ANONYMOUS) {
      return unhautorizedHanlder(axiosInstance, config, error);
    }
  }
  return Promise.reject(error);
}

function unhautorizedHanlder(
  axiosInstance: AxiosInstance,
  config: any,
  error: { response?: any; config: any }
): any {
  return jwtManager.getRefreshedToken().then(
    () =>
      axiosInstance.request({
        ...config,
        ...getDefaultAnonymousRequestConfig()
      }),
    () => Promise.reject(error)
  );
}
