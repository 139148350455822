import React from 'react';
import { useLocaleState } from 'react-admin';
import { AutoCompleteCell } from './EfficiencyTestDataTableAutoCompleteCell';
import { EditableCell } from './EfficiencyTestDataTableEditCell';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FlowTestvalue } from '../../../../../core/models/efficiency-tests/flowTestValue';
import { EfficiencyTestDataTablePlusCell } from './EfficiencyTestDataTablePlusCell';

type Props = {
  field: FlowTestvalue;
  row: number;
  onRemoveClick: () => void;
  diameterChoices: any[];
};

export const EfficiencyTestDataTableRow = ({
  field,
  row,
  onRemoveClick,
  diameterChoices
}: Props) => {
  const [locale] = useLocaleState();

  const resource = `flowTestValues.${row}`;

  const {
    control,
    formState: { errors }
  } = useFormContext();

  const {
    fields: diametersFields,
    append: addDiameter,
    remove: removeDiameter
  } = useFieldArray({
    control,
    name: `${resource}.diameters`
  });
  const { fields: mmHgLtFields } = useFieldArray({
    control,
    name: `${resource}.mmHgLt`
  });
  const { fields: mmHgLcFields } = useFieldArray({
    control,
    name: `${resource}.mmHgLc`
  });
  const {
    fields: holesOpenLcFields,
    append: addHolesOpenLc,
    remove: removeHolesOpenLc
  } = useFieldArray({
    control,
    name: `${resource}.holesOpenLc`
  });
  const {
    fields: holesOpenLtFields,
    append: addHolesOpenLt,
    remove: removeHolesOpenLt
  } = useFieldArray({
    control,
    name: `${resource}.holesOpenLt`
  });
  const { fields: aFields } = useFieldArray({
    control,
    name: `${resource}.a`
  });
  const { fields: cosFields } = useFieldArray({
    control,
    name: `${resource}.cos`
  });

  const handleRowRemoveClick = (i: number) => {
    if (diametersFields.length === 1) {
      onRemoveClick();
    } else {
      removeDiameter(i);
      removeHolesOpenLc(i);
      removeHolesOpenLt(i);
    }
  };

  const {
    diameters: diameterErrors,
    mmHgLt: mmHgLtErrors,
    mmHgLc: mmHgLcErrors,
    holesOpenLc: holesOpenLcErrors,
    holesOpenLt: holesOpenLtErrors,
    a: aErrors,
    cos: cosErrors
  } = errors?.flowTestValues?.[row] || {};

  return (
    <>
      <tr>
        <td role="cell">{field.m3min}</td>
        <td role="cell">
          <AutoCompleteCell
            fields={diametersFields}
            choices={(diameterChoices ?? []).map((choice: any) => ({
              id: choice.id,
              label: choice?.diameter
                ? choice?.diameter?.toLocaleString(locale)
                : ''
            }))}
            source={`flowTestValues.${row}.diameters`}
            errors={diameterErrors}
            onRowRemoveClick={(i) => handleRowRemoveClick(i)}
          />
        </td>
        <td role="cell">
          <EditableCell
            fields={mmHgLtFields}
            source={`flowTestValues.${row}.mmHgLt`}
            errors={mmHgLtErrors}
          />
        </td>
        <td role="cell">
          <EditableCell
            fields={mmHgLcFields}
            source={`flowTestValues.${row}.mmHgLc`}
            errors={mmHgLcErrors}
          />
        </td>
        <td role="cell">
          <EditableCell
            fields={holesOpenLtFields}
            source={`flowTestValues.${row}.holesOpenLt`}
            errors={holesOpenLtErrors}
          />
        </td>
        <td role="cell">
          <EditableCell
            fields={holesOpenLcFields}
            source={`flowTestValues.${row}.holesOpenLc`}
            errors={holesOpenLcErrors}
          />
        </td>
        <td role="cell">
          <EditableCell
            fields={aFields}
            source={`flowTestValues.${row}.a`}
            errors={aErrors}
          />
        </td>
        <td role="cell">
          <EditableCell
            fields={cosFields}
            source={`flowTestValues.${row}.cos`}
            errors={cosErrors}
          />
        </td>
        <td role="cell">{field.kwCurve}</td>
      </tr>
      <tr>
        <EfficiencyTestDataTablePlusCell
          onClick={() => {
            addDiameter({ value: '' });
            addHolesOpenLc({ value: '' });
            addHolesOpenLt({ value: '' });
          }}
        />
      </tr>
    </>
  );
};
