import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { DiameterDeleteDialog } from './DiameterDeleteDialog';

export const DiameterDeleteButton = (props: any) => {
  const record: any = useRecordContext(props);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const handleDeleteDiameter = () => setShowDeleteDialog(true);

  return (
    <>
      <IconButton onClick={() => handleDeleteDiameter()}>
        <DeleteIcon />
      </IconButton>
      <DiameterDeleteDialog
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        id={record.id}
      />
    </>
  );
};
