import React from 'react';
import { TopToolbar, usePermissions } from 'react-admin';
import { PrivilegeType } from '../../../../core/models/privileges/privilegeType';
import { some } from '../../../../core/utils/collection-utils';
import { PumpsSpeedCreateButton } from './PumpsSpeedCreateButton';

export const PumpsSpeedListActions = () => {
  const { permissions } = usePermissions();
  return (
    <TopToolbar>
      {some([PrivilegeType.MANAGE_PUMPS], permissions) && (
        <PumpsSpeedCreateButton />
      )}
    </TopToolbar>
  );
};
