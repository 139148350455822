import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import {
  CheckboxGroupInput,
  required,
  TextInput,
  usePermissions,
  useTranslate
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { PrivilegeType } from '../../../core/models/privileges/privilegeType';
import { getPrivileges } from '../../../core/services/privilege-api.service';
import {
  findElementByPropertyValue,
  mapCollection,
  push,
  reduce,
  some,
  sort
} from '../../../core/utils/collection-utils';

interface PrivilegeGroupItem {
  key: string;
  items: any[];
}

export const UsersForm = () => {
  const { permissions } = usePermissions();
  const t = useTranslate();

  const [privilegeChoices, setPrivilegeChoices] = useState<
    PrivilegeGroupItem[]
  >([]);

  useEffect(() => {
    getPrivilegeChoices();
  }, []);

  const { watch, setValue } = useFormContext();
  const privileges = watch('privileges');

  useEffect(() => {
    const transformed = mapCollection(
      (privilege: any) =>
        typeof privilege === 'object' ? privilege.id : privilege,
      privileges || []
    );
    if (JSON.stringify(privileges) !== JSON.stringify(transformed)) {
      setValue('privileges', transformed);
    }
    // eslint-disable-next-line
  }, [privileges]);

  let getPrivilegeChoices = async () => {
    let privileges = await getPrivileges();

    const transform = reduce(
      (acc: any, item: any) => {
        const existingGroup = findElementByPropertyValue(
          acc,
          'key',
          item.group
        );
        if (existingGroup) {
          existingGroup.items = sort(
            push(existingGroup.items, item),
            (a: any, b: any) => a.position - b.position
          );
        } else {
          acc = push(acc, { key: item.group, items: [item] });
        }
        return acc;
      },
      [],
      privileges.data
    ) as PrivilegeGroupItem[];

    setPrivilegeChoices(transform);
  };

  const disabled = !some([PrivilegeType.MANAGE_USERS], permissions);

  return (
    <div>
      <Grid container rowSpacing={0} columnSpacing={3}>
        <Grid item xs={12} sm={12}>
          <TextInput
            source="firstName"
            validate={[required()]}
            disabled={disabled}
            label="resources.users.fields.firstName"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            source="lastName"
            validate={[required()]}
            disabled={disabled}
            label="resources.users.fields.lastName"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            source="secondLastName"
            validate={[]}
            disabled={disabled}
            label="resources.users.fields.secondLastName"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            source="email"
            validate={[required()]}
            disabled={disabled}
            label="resources.users.fields.email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            source="phone"
            validate={[]}
            disabled={disabled}
            label="resources.users.fields.phone"
          />
        </Grid>
      </Grid>
      <div className="user-permissions">
        <div className="user-permissions__header">
          <span>{t('resources.users.fields.permissions')}</span>
          <span>{t('resources.users.fields.view')}</span>
          <span>{t('resources.users.fields.manage')}</span>
        </div>
        {privilegeChoices.map((group) => {
          return (
            <CheckboxGroupInput
              key={group.key}
              source="privileges"
              label={`resources.users.fields.${group.key}`}
              choices={group.items}
              optionText={(_) => ''}
              disabled={disabled}
            />
          );
        })}
      </div>
    </div>
  );
};
