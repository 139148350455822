import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { IconButton } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';

export const EfficiencyTestEnterButton = (props: any) => {
  const navigate = useNavigate();
  const record: any = useRecordContext(props);

  function openEfficiencyTest() {
    navigate(`/efficiency-tests/${record.id}`);
  }

  return (
    <>
      <IconButton onClick={() => openEfficiencyTest()}>
        <ArrowRightAltIcon />
      </IconButton>
    </>
  );
};
