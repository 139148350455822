import React from 'react';
import { RaRecord, TextInput } from 'react-admin';
import Box from '@mui/material/Box';

export const EfficiencyTestObservationsField = (props: {
  record: RaRecord;
}) => {
  const record = props.record;
  return (
    <Box
      sx={{
        width: '100%',
        marginTop: 2
      }}
    >
      <TextInput
        rows="3"
        source="observations"
        label="resources.efficiency-tests.fields.observations"
        defaultValue={record?.observations}
        multiline={true}
        fullWidth={true}
      />
    </Box>
  );
};
