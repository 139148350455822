import React from 'react';
import { Show } from 'react-admin';
import { useParams } from 'react-router-dom';
import { EfficiencyTestOverviewTabs } from './EfficiencyTestOverviewTabs';

export const EfficiencyTestOverview = () => {
  const params = useParams();

  return (
    <Show
      resource={'efficiency-tests'}
      id={params.efficiencyTestId}
      title="resources.efficiency-tests.fields.testDetail"
    >
      <EfficiencyTestOverviewTabs />
    </Show>
  );
};
