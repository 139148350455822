import React, { FC } from 'react';
import { Layout } from 'react-admin';
import { useResponsiveSideBar } from '../../core/hooks/useResponsiveSideBar';

import { CustomMenu } from './menu/CustomMenu';

export const CustomLayout: FC = (props) => {
  useResponsiveSideBar();
  return <Layout {...props} menu={CustomMenu}></Layout>;
};
