import Container from '@mui/material/Container';
import React from 'react';
import {
  DateField,
  RaRecord,
  SelectInput,
  TextField,
  required,
  useTranslate
} from 'react-admin';
import { isEmpty } from '../../../../core/utils/object-utils';
import { EfficiencyTestsDownloadDataPdfButton } from '../../efficiencyTestsShared/EfficienctTestsDownloadDataPdfButton';
import usePumps from '../../../../core/hooks/usePumps';

type Props = {
  record: RaRecord;
};

export const EfficiencyTestDataHeader = ({ record }: Props) => {
  const { efficiencyTestResults } = record;
  const t = useTranslate();
  const isButtonDisabled = isEmpty(efficiencyTestResults);

  const pumps = usePumps();

  return (
    <Container maxWidth={false} sx={{ padding: 0 }}>
      <div className="print-buttons">
        <EfficiencyTestsDownloadDataPdfButton
          disabled={isButtonDisabled}
          efficiencyTest={record}
        />
      </div>
      <ul className="column-list column-list-3">
        <li>
          <span>
            <label>{t('resources.efficiency-tests.fields.pumpModel')}</label>
          </span>
          <span>
            <SelectInput
              source="pumpHash"
              choices={(pumps.data ?? []).map((p) => ({
                id: p.id,
                name: p.pumpName
              }))}
              validate={required()}
              defaultValue={record.pump?.id}
              sx={{
                '& > p': {
                  display: 'none'
                }
              }}
            />
          </span>
        </li>
        <li>
          <span>
            <label>{t('resources.efficiency-tests.fields.client')}</label>
          </span>
          <span>
            <TextField record={record} source="customer.name" />
          </span>
        </li>
        <li>
          <span>
            <label>{t('resources.efficiency-tests.fields.identifier')}</label>
          </span>
          <span>
            <TextField record={record} source="identifier" />
          </span>
        </li>
        <li>
          <span>
            <label>{t('resources.efficiency-tests.fields.satref')}</label>
          </span>
          <span>
            <TextField record={record} source="satref" />
          </span>
        </li>
        <li>
          <span>
            <label>{t('resources.efficiency-tests.fields.orderNumber')}</label>
          </span>
          <span>
            <TextField record={record} source="orderNumber" />
          </span>
        </li>
        <li>
          <span>
            <label>{t('resources.efficiency-tests.fields.date')}</label>
          </span>
          <span>
            <DateField record={record} source="date" />
          </span>
        </li>
      </ul>
    </Container>
  );
};
