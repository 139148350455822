import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { PumpsSpeedDeleteDialog } from './PumpsSpeedDeleteDialog';

export const PumpsSpeedDeleteButton = (props: any) => {
  const record: any = useRecordContext(props);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const handleDeletePump = () => setShowDeleteDialog(true);

  return (
    <>
      <IconButton onClick={() => handleDeletePump()}>
        <DeleteIcon />
      </IconButton>
      <PumpsSpeedDeleteDialog
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        id={record.id}
      />
    </>
  );
};
