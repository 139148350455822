import React from 'react';
import { Logout, Menu, usePermissions } from 'react-admin';
import { PrivilegeType } from '../../../core/models/privileges/privilegeType';
import { some } from '../../../core/utils/collection-utils';

export const CustomMenu = () => {
  const { permissions } = usePermissions();
  const {
    VIEW_EFFICIENCY_TEST,
    MANAGE_EFFICIENCY_TEST,
    VIEW_DIAMETERS,
    MANAGE_DIAMETERS,
    VIEW_PUMPS,
    MANAGE_PUMPS,
    VIEW_USERS,
    MANAGE_USERS
  } = PrivilegeType;

  return (
    <Menu className="custom-menu">
      <div className="logo">
        <img
          src="src/images/logo-safem.png"
          alt="SAFEM"
          width="161"
          height="45"
        />
      </div>
      {some([VIEW_EFFICIENCY_TEST, MANAGE_EFFICIENCY_TEST], permissions) && (
        <Menu.ResourceItem name="efficiency-tests" />
      )}
      {some([VIEW_PUMPS, MANAGE_PUMPS], permissions) && (
        <Menu.ResourceItem name="pumps" />
      )}
      {some([VIEW_DIAMETERS, MANAGE_DIAMETERS], permissions) && (
        <Menu.ResourceItem name="diameters" />
      )}
      {some([VIEW_USERS, MANAGE_USERS], permissions) && (
        <Menu.ResourceItem name="users" />
      )}
      <Logout className="logout-link" />
    </Menu>
  );
};
