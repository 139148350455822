import React from 'react';
import {
  CreateButton,
  FilterLiveSearch,
  TopToolbar,
  usePermissions,
  useTranslate
} from 'react-admin';
import { PrivilegeType } from '../../../core/models/privileges/privilegeType';
import { some } from '../../../core/utils/collection-utils';

export const DiametersListActions = () => {
  const { permissions } = usePermissions();
  const t = useTranslate();
  return (
    <TopToolbar>
      <FilterLiveSearch
        source="searchText"
        placeholder={t('resources.diameters.filters.search')}
      />
      {some([PrivilegeType.MANAGE_DIAMETERS], permissions) && (
        <CreateButton resource="/diameters" />
      )}
    </TopToolbar>
  );
};
