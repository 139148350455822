export const HEADER_ANONYMOUS = 'Anonymous';

export const HEADER_AVOID_ERROR_HANDLING = 'Avoid-Error-Handling';

export const HEADER_ERROR_HANDLING = 'Error-Handling';

export const HEADER_PENDING_BLOCKING = 'Blocking';

export const HEADER_PENDING_NO_BLOCKING = 'No-Blocking';

export const HEADER_ERROR_PREFIX = 'Error-Prefix';
