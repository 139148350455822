import React from 'react';
import {
  ArrayField,
  Datagrid,
  NumberField,
  Show,
  SimpleShowLayout,
  useTranslate
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { ListHeader } from '../../../../components/shared/ListHeader';
import { TableCell } from '../../../../components/shared/TableCell';

export const PumpsSpeedShow = () => {
  const params = useParams();
  const t = useTranslate();
  return (
    <Show
      resource={'pumps/speeds'}
      id={params.speedId}
      title="resources.pumps.speeds.fields.speedDetail"
    >
      <SimpleShowLayout>
        <ArrayField
          source="pumpLines"
          label="resources.pumps.speeds.fields.pumpLines"
        >
          <Datagrid
            bulkActionButtons={false}
            header={
              <ListHeader
                columns={
                  <>
                    <TableCell>
                      {t('resources.pumps.speeds.fields.pumpQuantity')}
                    </TableCell>
                    <TableCell>
                      {t('resources.pumps.speeds.fields.capacityReqd')}
                    </TableCell>
                    <TableCell>
                      {t('resources.pumps.speeds.fields.capacityActual')}
                    </TableCell>
                    <TableCell>
                      {t('resources.pumps.speeds.fields.vacuum')}
                    </TableCell>
                    <TableCell>
                      {t('resources.pumps.speeds.fields.vaporTemp')}
                    </TableCell>
                    <TableCell>
                      {t('resources.pumps.speeds.fields.sealWaterTemp')}
                    </TableCell>
                    <TableCell>
                      {t('resources.pumps.speeds.fields.efficiency')}
                    </TableCell>
                    <TableCell>
                      {t('resources.pumps.speeds.fields.sealFlow')}
                    </TableCell>
                    <TableCell>
                      {t('resources.pumps.speeds.fields.exitTemp')}
                    </TableCell>
                    <TableCell>
                      {t('resources.pumps.speeds.fields.pumpSpeed')}
                    </TableCell>
                    <TableCell>
                      {t('resources.pumps.speeds.fields.powerAbsorved')}
                    </TableCell>
                    <TableCell>
                      {t('resources.pumps.speeds.fields.motorKw')}
                    </TableCell>
                    <TableCell>
                      {t('resources.pumps.speeds.fields.motorRpm')}
                    </TableCell>
                    <TableCell>
                      {t('resources.pumps.speeds.fields.dryAir')}
                    </TableCell>
                    <TableCell>
                      {t('resources.pumps.speeds.fields.exitAir')}
                    </TableCell>
                  </>
                }
                deleteColumn={false}
                enterColumn={false}
              />
            }
          >
            <NumberField
              textAlign={'left'}
              options={{ minimumFractionDigits: 2 }}
              source="quantity"
            />
            <NumberField
              textAlign={'left'}
              options={{ minimumFractionDigits: 2 }}
              source="capacityReqd"
            />
            <NumberField
              textAlign={'left'}
              options={{ minimumFractionDigits: 2 }}
              source="capacityActual"
            />
            <NumberField
              textAlign={'left'}
              options={{ minimumFractionDigits: 2 }}
              source="vacuum"
            />
            <NumberField
              textAlign={'left'}
              options={{ minimumFractionDigits: 2 }}
              source="vaporTemperature"
            />
            <NumberField
              textAlign={'left'}
              options={{ minimumFractionDigits: 2 }}
              source="sealWaterTemperature"
            />
            <NumberField
              textAlign={'left'}
              options={{ minimumFractionDigits: 4 }}
              source="efficiency"
            />
            <NumberField
              textAlign={'left'}
              options={{ minimumFractionDigits: 2 }}
              source="sealFlow"
            />
            <NumberField
              textAlign={'left'}
              options={{ minimumFractionDigits: 2 }}
              source="exitTemperature"
            />
            <NumberField
              textAlign={'left'}
              options={{ minimumFractionDigits: 2 }}
              source="pumpSpeed"
            />
            <NumberField
              textAlign={'left'}
              options={{ minimumFractionDigits: 2 }}
              source="powerAbsorbed"
            />
            <NumberField
              textAlign={'left'}
              options={{ minimumFractionDigits: 2 }}
              source="motorKw"
            />
            <NumberField
              textAlign={'left'}
              options={{ minimumFractionDigits: 2 }}
              source="motorRpm"
            />
            <NumberField
              textAlign={'left'}
              options={{ minimumFractionDigits: 2 }}
              source="stdDryAir"
            />
            <NumberField
              textAlign={'left'}
              options={{ minimumFractionDigits: 2 }}
              source="exitAir"
            />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
