import * as FileSaver from 'file-saver';

export const toBlob = (data: ArrayBuffer) => new Blob([new Uint8Array(data)]);

export const downloadFile = (file: Blob | string, fileName: string) =>
  FileSaver.saveAs(file, fileName);

export const fileListToFileArray = (fileList: FileList) => {
  const files: File[] = [];
  for (let i = 0; i < fileList.length; i++) {
    files.push(fileList.item(i) as File);
  }
  return files;
};

export const appendFileListToFormData = (
  formData: FormData,
  fileList: FileList
) => {
  const files = fileListToFileArray(fileList);
  files.forEach((file) => formData.append('documents', file));
};

export const convertFileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
