import { VALIDATION_REQUIRED } from '../../../core/constants/validation.constants';

export function efficiencyTestsFormValidator(values: any) {
  const errors: any = {};

  if (!values.pumpHash) {
    errors.pumpHash = VALIDATION_REQUIRED;
  }
  if (!values.customerHash) {
    errors.customerHash = VALIDATION_REQUIRED;
  }
  if (!values.date) {
    errors.date = VALIDATION_REQUIRED;
  }

  return errors;
}
