import { VALIDATION_REQUIRED } from '../../../../core/constants/validation.constants';

export function efficiencyTestsDataFormValidator(values: any) {
  const errors: any = {};
  if (values && values.flowTestValues) {
    values.flowTestValues.forEach((v, valueIndex) => {
      Object.entries(v).forEach(([key, arr]: [string, any[]]) => {
        if (Array.isArray(arr)) {
          (arr ?? []).forEach((elem, elemIndex) => {
            if (
              elem.value === '' ||
              elem.value === null ||
              elem.value === undefined
            ) {
              errors.flowTestValues ??= [];
              errors.flowTestValues[valueIndex] ??= {};
              errors.flowTestValues[valueIndex][key] ??= [];
              errors.flowTestValues[valueIndex][key][elemIndex] =
                VALIDATION_REQUIRED;
            }
          });
        }
      });
    });
  }
  const requiredFields = [
    'voltage',
    'tWater',
    'paMmbar',
    'pumpRpm',
    'aspirationTemperature',
    'effFactor',
    'waterFlowM3H'
  ];
  for (const field of requiredFields) {
    if (!values[field]) {
      errors[field] = VALIDATION_REQUIRED;
    }
  }

  return errors;
}
