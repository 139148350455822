export enum PrivilegeType {
  VIEW_EFFICIENCY_TEST = 'VIEW_EFFICIENCY_TEST',
  MANAGE_EFFICIENCY_TEST = 'MANAGE_EFFICIENCY_TEST',
  VIEW_PUMPS = 'VIEW_PUMPS',
  MANAGE_PUMPS = 'MANAGE_PUMPS',
  VIEW_DIAMETERS = 'VIEW_DIAMETERS',
  MANAGE_DIAMETERS = 'MANAGE_DIAMETERS',
  VIEW_USERS = 'VIEW_USERS',
  MANAGE_USERS = 'MANAGE_USERS'
}
