import polyglotI18nProvider from 'ra-i18n-polyglot';
import { resolveBrowserLocale } from 'react-admin';
import es from './i18n/es';

const translations = { es };

export const LANGS = [{ code: 'es', label: 'Español' }];

export const i18nProvider = polyglotI18nProvider(
  //@ts-ignore
  (locale) => {
    import('moment/locale/' + locale);
    return translations[locale];
  },
  resolveBrowserLocale()
);
