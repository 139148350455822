import React, { useEffect, useState } from 'react';
import {
  AutocompleteInput,
  DateInput,
  required,
  SelectInput,
  TextInput
} from 'react-admin';
import { getCustomers } from '../../../core/services/customer-api.service';
import { getPumps } from '../../../core/services/pump-api.service';
import { EfficiencyTestCreateCustomer } from './EfficiencyTestCreateCustomer';
import Grid from '@mui/material/Grid';

export const EfficiencyTestsForm = () => {
  const [pumpChoices, setPumpChoices] = useState([]);
  const [customerChoices, setCustomerChoices] = useState([]);

  useEffect(() => {
    getPumpChoices();
    getCustomerChoices();
  }, []);

  let getPumpChoices = async () => {
    let pumps = await getPumps();
    const transform = pumps?.data.map((pump: any) => {
      return { id: pump?.id, name: pump?.pumpName };
    });
    setPumpChoices(transform);
  };

  let getCustomerChoices = async () => {
    let customers = await getCustomers();
    const transform = customers?.data.map((customer: any) => {
      return { id: customer?.id, name: customer?.name };
    });
    setCustomerChoices(transform);
  };

  return (
    <Grid container rowSpacing={0} columnSpacing={3}>
      <SelectInput
        optionText="name"
        source="pumpHash"
        label="resources.efficiency-tests.fields.pumpModel"
        validate={[required()]}
        choices={pumpChoices}
      />
      <AutocompleteInput
        choices={customerChoices}
        source="customerHash"
        label="resources.efficiency-tests.fields.client"
        createItemLabel="resources.efficiency-tests.fields.create"
        create={
          <EfficiencyTestCreateCustomer refreshCustomers={getCustomerChoices} />
        }
      />
      <TextInput
        source="identifier"
        label="resources.efficiency-tests.fields.identifier"
      />
      <TextInput
        source="satref"
        label="resources.efficiency-tests.fields.satref"
      />
      <TextInput
        source="orderNumber"
        label="resources.efficiency-tests.fields.orderNumber"
      />
      <DateInput
        label="resources.efficiency-tests.fields.date"
        source="date"
        validate={[required()]}
      />
    </Grid>
  );
};
