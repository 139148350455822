import React from 'react';
import {
  ArrayField,
  Datagrid,
  NumberField,
  Show,
  SimpleShowLayout
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { ListHeader } from '../../../../components/shared/ListHeader';
import { TableCell } from '../../../../components/shared/TableCell';

export const DiametersFlowsShow = () => {
  const params = useParams();
  return (
    <Show
      resource={'diameters'}
      id={params.diameterId}
      title="resources.diameters.flows.fields.flowDetail"
    >
      <SimpleShowLayout>
        <ArrayField
          source="diameterFlows"
          label="resources.diameters.flows.fields.flowLines"
        >
          <Datagrid
            bulkActionButtons={false}
            header={
              <ListHeader
                columns={
                  <>
                    <TableCell>inHg</TableCell>
                    <TableCell>mmHg</TableCell>
                    <TableCell>m3/min</TableCell>
                    <TableCell>m3/h</TableCell>
                  </>
                }
                deleteColumn={false}
                enterColumn={false}
              />
            }
          >
            <NumberField
              textAlign={'left'}
              options={{ minimumFractionDigits: 2 }}
              source="inHg"
            />
            <NumberField
              textAlign={'left'}
              options={{ minimumFractionDigits: 2 }}
              source="mmHg"
            />
            <NumberField
              textAlign={'left'}
              options={{ minimumFractionDigits: 2 }}
              source="m3Min"
            />
            <NumberField
              textAlign={'left'}
              options={{ minimumFractionDigits: 2 }}
              source="m3Hour"
            />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
