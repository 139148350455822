import React from 'react';
import {
  Datagrid,
  List,
  NumberField,
  usePermissions,
  useTranslate
} from 'react-admin';
import { EmptyList } from '../../../components/shared/EmptyList';
import { ListHeader } from '../../../components/shared/ListHeader';
import { TableCell } from '../../../components/shared/TableCell';
import { PER_PAGE } from '../../../core/constants/pagination.constants';
import { PrivilegeType } from '../../../core/models/privileges/privilegeType';
import { some } from '../../../core/utils/collection-utils';
import { DiameterDeleteButton } from './DiameterDeleteButton';
import { DiameterEnterButton } from './DiameterEnterButton';
import { DiametersListActions } from './DiametersActions';

export const DiametersList = () => {
  const { permissions } = usePermissions();
  const t = useTranslate();
  const hasDeletePermissions = some(
    [PrivilegeType.MANAGE_DIAMETERS],
    permissions
  );
  return (
    <List
      resource="diameters/paginated"
      actions={<DiametersListActions />}
      empty={
        <EmptyList title={t('shared.list.empty.title')}>
          <DiametersListActions />
        </EmptyList>
      }
      debounce={200}
      perPage={PER_PAGE}
      sort={{ field: 'diameter', order: 'ASC' }}
      title={t('resources.diameters.name', 2)}
    >
      <Datagrid
        bulkActionButtons={false}
        header={
          <ListHeader
            columns={
              <>
                <TableCell source="diameter">
                  {t('resources.diameters.fields.diameter')}
                </TableCell>
              </>
            }
            deleteColumn={hasDeletePermissions}
            enterColumn={true}
          />
        }
      >
        <NumberField
          source="diameter"
          textAlign="left"
          options={{
            maximumFractionDigits: 3,
            minimumFractionDigits: 3
          }}
        />
        {hasDeletePermissions && <DiameterDeleteButton />}
        <DiameterEnterButton />
      </Datagrid>
    </List>
  );
};
