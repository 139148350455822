import decodeJwt from 'jwt-decode';
import { AuthProvider } from 'react-admin';
import { LOGIN_URL } from '../constants/url.constants';
import jwtManager from './jwtManager';

const apiUrl = process.env.REACT_APP_API_URL;
const authProvider: AuthProvider = {
  login: login(),
  logout: logout(),
  checkAuth: checkAuth(),
  checkError: checkError(),
  getPermissions: getPermissions()
};

export default authProvider;

function getPermissions(): (params: any) => Promise<any[]> {
  return async () => {
    await jwtManager.waitForTokenRefresh();
    const token = jwtManager.getToken();
    let role = null;
    if (token) {
      const decodedToken: { privileges: string } = decodeJwt(token);
      role = decodedToken.privileges;
    }
    return role ? Promise.resolve(role.split(',')) : Promise.reject();
  };
}

function checkError(): (error: any) => Promise<void> {
  return async (error) => {
    const status = error?.status;
    if (status === 401 || status === 403) {
      jwtManager.deleteToken();
      return Promise.reject();
    }

    return Promise.resolve();
  };
}

function checkAuth(): (params: any) => Promise<void> {
  return async () => {
    await jwtManager.waitForTokenRefresh();
    return jwtManager.getToken() ? Promise.resolve() : Promise.reject();
  };
}

function logout(): (params: any) => Promise<string | false | void> {
  return async () => {
    jwtManager.deleteToken();
    return LOGIN_URL;
  };
}

function login(): (params: any) => Promise<any> {
  return async ({ username, password, remindUser }) => {
    const request = new Request(`${apiUrl}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
      credentials: 'include'
    });
    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    const { token, refreshToken } = await response.json();
    if (remindUser) {
      return jwtManager.setToken(token, refreshToken);
    } else {
      return jwtManager.setToken(token, '');
    }
  };
}
