import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function DividerIcon(props: any) {
  const propsMerged = {
    ...props,
    //viewPort: '20 20',
    viewBox: '-4 -2 24 24'
  };
  return (
    <SvgIcon {...propsMerged}>
      <path d="M16.148 6.572h-4.426V5.087h4.426zm0 8.341h-4.426v-1.485h4.426zm-.475-7.341h.475a1 1 0 0 0 1-1V5.087a1 1 0 0 0-1-1h-4.426a1 1 0 0 0-1 1v1.485a1 1 0 0 0 1 1h.475v4.856h-.475a1 1 0 0 0-1 1v1.485a1 1 0 0 0 1 1h4.426a1 1 0 0 0 1-1v-1.485a1 1 0 0 0-1-1h-.475zm-2.476 0h1.476v4.856h-1.476zM8.85 10.659h-7V9.174h7zm0 8.341h-7v-1.485h7zm0-16.515h-7V1h7zm-5.49 9.174h3.99v4.856H3.36zm3.99-3.485H3.36V3.485h3.99zm-4.99 0h-.51a1 1 0 0 0-1 1v1.485a1 1 0 0 0 1 1h.51v4.856h-.51c-.552 0-1 .485-1 1V19a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-1.485c0-.515-.448-1-1-1h-.5v-4.856h.5a1 1 0 0 0 1-1V9.174a1 1 0 0 0-1-1h-.5V3.485h.5c.552 0 1-.485 1-1V1a1 1 0 0 0-1-1h-7a1 1 0 0 0-1 1v1.485c0 .515.448 1 1 1h.51z" />
    </SvgIcon>
  );
}
