import { Button, Box, Card, CardActions, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Form, PasswordInput, useNotify, useTranslate } from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';
import { HeaderLogo } from '../components/shared/HeaderLogo';
import { LOGIN_URL } from '../core/constants/url.constants';
import { resetPassword } from '../core/services/auth-api.service';
import { getErrorMessageCode } from '../core/utils/error.utils';
import { passWordValidation } from '../core/utils/validation-utils';

export const ResetPassword = () => {
  const t = useTranslate();
  const params = useParams();
  const notify = useNotify();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onResetPassword = (event: FormValues): void => {
    setLoading(true);
    resetPassword(params.id!, event.password!, event.confirmPassword!)
      .then((response) => {
        setLoading(false);
        const sucessKey = 'resources.users.notifications.resetPassword.success';
        notify(sucessKey, { type: 'success' });
        navigate(LOGIN_URL);
      })
      .catch((error) => {
        setLoading(false);
        const code = getErrorMessageCode(error);
        notify(`errors.${code.toLowerCase()}`, { type: 'error' });
      });
  };

  return (
    <div id="login-page">
      <div className="login-container">
        <HeaderLogo />
        <Typography
          className="login-text"
          component="p"
          align="center"
          gutterBottom
        >
          {t('resources.auth.reset_password.subtitle')}
        </Typography>
        <Form
          onSubmit={onResetPassword}
          validate={passWordValidation}
          className="form-dark"
        >
          <Box id="login-box">
            <Card
              variant="outlined"
              sx={{
                bgcolor: 'transparent',
                border: 0
              }}
            >
              <Box>
                <Box>
                  <PasswordInput
                    autoFocus
                    fullWidth
                    size="medium"
                    InputLabelProps={{ shrink: true }}
                    source="password"
                    label="resources.auth.reset_password.form.password"
                    disabled={loading}
                    inputProps={{ autoComplete: 'current-password' }}
                  />
                </Box>
                <Box>
                  <PasswordInput
                    autoFocus
                    fullWidth
                    size="medium"
                    InputLabelProps={{ shrink: true }}
                    source="confirmPassword"
                    label="resources.auth.reset_password.form.confirmPassword"
                    disabled={loading}
                    inputProps={{ autoComplete: 'current-password' }}
                  />
                </Box>
              </Box>
              <CardActions
                sx={{
                  p: 0,
                  mt: 1
                }}
              >
                <Button
                  sx={{ color: 'theme.palette.primary' }}
                  variant="contained"
                  fullWidth
                  size="large"
                  color="secondary"
                  disabled={loading}
                  type="submit"
                >
                  {t('resources.auth.recovery_password.button')}
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Form>
      </div>
    </div>
  );
};

interface FormValues {
  password?: string;
  confirmPassword?: string;
}
