import React, { useEffect, useState } from 'react';
import { TabbedShowLayout, useRecordContext } from 'react-admin';
import { isEmpty } from '../../../core/utils/object-utils';
import { EfficiencyTestCurvesCharts } from './efficiencyTestCurves/EfficiencyTestCurvesCharts';
import { EfficiencyTestForm } from './efficiencyTestData/EfficiencyTestForm';
import { EfficiencyTestResults } from './efficiencyTestResults/EfficiencyTestResults';

export const EfficiencyTestOverviewTabs = () => {
  const [hasResults, setHasResults] = useState(false);
  const record = useRecordContext();

  useEffect(() => {
    if (record && record.efficiencyTestResults) {
      setHasResults(isEmpty(record.efficiencyTestResults));
    }
    // eslint-disable-next-line
  }, [record]);

  return (
    <TabbedShowLayout syncWithLocation={false}>
      <TabbedShowLayout.Tab label="resources.efficiency-tests.tabs.data">
        <EfficiencyTestForm />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="resources.efficiency-tests.tabs.results"
        disabled={hasResults}
      >
        <EfficiencyTestResults />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="resources.efficiency-tests.tabs.curves"
        disabled={hasResults}
      >
        <EfficiencyTestCurvesCharts />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  );
};
