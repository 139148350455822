import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React from 'react';
import { useTranslate } from 'react-admin';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { EfficiencyTestTableInitialData } from '../../../../../core/utils/efficiency-test-utils';
import { FlowTestvalue } from '../../../../../core/models/efficiency-tests/flowTestValue';
import { EfficiencyTestDataTableRow } from './EfficiencyTestDataTableRow';
import useDiameters from '../../../../../core/hooks/useDiameters';

export const EfficiencyTestDataTable = () => {
  const t = useTranslate();

  const { control } = useFormContext();
  const diameters = useDiameters();

  const {
    fields: flowTestValues,
    append: addRow,
    remove: removeRow
  } = useFieldArray({
    control,
    name: 'flowTestValues'
  });

  return (
    <Box className="test-data__table">
      <table className="custom-table">
        <thead>
          <tr>
            <th>{t('resources.efficiency-tests.fields.testFlow')}</th>
            <th>{t('resources.efficiency-tests.fields.diameter')}</th>
            <th colSpan={2}>
              {t('resources.efficiency-tests.fields.emptyMmhg')}
            </th>
            <th colSpan={2}>
              {t('resources.efficiency-tests.fields.openHoles')}
            </th>
            <th colSpan={2}>
              {t('resources.efficiency-tests.fields.consume')}
            </th>
            <th>{t('resources.efficiency-tests.fields.curveKw')}</th>
          </tr>
          <tr>
            <th>{t('resources.efficiency-tests.fields.m3min')}</th>
            <th>{t('resources.efficiency-tests.fields.mm')}</th>
            <th>{t('resources.efficiency-tests.fields.lt')}</th>
            <th>{t('resources.efficiency-tests.fields.lc')}</th>
            <th>{t('resources.efficiency-tests.fields.lt')}</th>
            <th>{t('resources.efficiency-tests.fields.lc')}</th>
            <th>{t('resources.efficiency-tests.fields.a')}</th>
            <th>{t('resources.efficiency-tests.fields.cos')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {flowTestValues.map((field: FlowTestvalue & { id: string }, i) => (
            <React.Fragment key={field.id}>
              <EfficiencyTestDataTableRow
                field={field}
                row={i}
                onRemoveClick={() => removeRow(i)}
                diameterChoices={diameters.data ?? []}
              />
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <Button
        className="button-plus button-plus--row"
        onClick={() => {
          addRow(EfficiencyTestTableInitialData(diameters.data ?? []));
        }}
        tabIndex={-1}
      >
        + {t('resources.efficiency-tests.table.addRow')}
      </Button>
    </Box>
  );
};
