import * as R from 'ramda';

import { max, min, sum } from './number-utils';
import { isNullOrUndefined } from './object-utils';
import { compare } from './string-utils';

export const compareStringProperties = R.curry(
  (property: string, reverse: boolean, a: any, b: any) => {
    const reverseFactor = reverse ? -1 : 1;

    return reverseFactor * compare(a[property], b[property]);
  }
);

export const push = <T>(collection: T[], element: T) =>
  Array.isArray(collection) ? [...collection, element] : [element];

const equalsProperty = R.curry((property: string, value: any, element: any) => {
  return element[property] === value;
});

export const findIndex = <T>(
  predicate: (element: T) => boolean,
  collection: T[]
) => (Array.isArray(collection) ? collection.findIndex(predicate) : -1);

export const isUniqueInCollectionByProperty = R.curry(
  (property: string, element: any, index: number, collection: any[]) => {
    return (
      findIndex(equalsProperty(property, element[property]), collection) ===
      index
    );
  }
);

export const filter = R.curry(
  <T>(predicate: (element: T) => boolean, collection: T[]): T[] =>
    Array.isArray(collection) ? collection.filter(predicate) : []
);

export const findElementByPropertyValue = (
  elements: any[],
  property: string,
  value: any
) =>
  Array.isArray(elements)
    ? elements.find((element) => element[property] === value)
    : null;

export const unique = <T>(collection: T[]) => Array.from(new Set(collection));

export const sumUpValues = (values: number[]) =>
  Array.isArray(values) ? values.reduce(sum, 0) : null;

export const minValue = (values: number[]) =>
  Array.isArray(values) ? values.reduce(min, 999999) : null;

export const maxValue = (values: number[]) =>
  Array.isArray(values) ? values.reduce(max, -999999) : null;

export const mapCollection = <T>(
  mapFunction: (element: T, i?: number, array?: T[]) => any,
  collection: T[]
) => (Array.isArray(collection) ? collection.map(mapFunction) : []);

export const intArray = (value: number) =>
  Array.from({ length: value }, (_v, k) => k);

export const length = <T>(collection: T[]) =>
  !isNullOrUndefined(collection) && Array.isArray(collection)
    ? collection.length
    : 0;

export const concat = <T>(...collections: T[][]) =>
  Array.isArray(collections)
    ? collections.reduce(
        (acc: T[], col: T[]) =>
          Array.isArray(col) ? [...acc, ...col] : [...acc],
        []
      )
    : [];

export const isEmpty = <T>(collection: T[]) => !length(collection);

export const reduce = R.curry(
  <T, V>(
    reductionFunction: (reduced: V, element: T) => V,
    initialValue: V,
    collection: T[]
  ) =>
    Array.isArray(collection)
      ? collection.reduce(reductionFunction, initialValue)
      : null
);

export const merge = <T>(a: T[], b: T[]) =>
  Array.isArray(a) && Array.isArray(b)
    ? [...a, ...b]
    : Array.isArray(a)
    ? [...a]
    : Array.isArray(b)
    ? [...b]
    : [];

export const allEqual = <T>(collection: T[]) =>
  Array.isArray(collection)
    ? collection.every((element, i, arr) => element === arr[0])
    : false;

export const limit = <T>(count: number, collection: T[]): T[] =>
  Array.isArray(collection) ? collection.slice(0, count) : [];

export const existsIndex = <T>(index: number, collection: T[]): boolean =>
  index > -1 && index < length(collection);

export const some = <T>(collection: T[], array: any[]): boolean => {
  if (array === undefined) {
    return false;
  }
  return collection.some((element) => array.includes(element));
};

export const sort = <T>(
  collection: T[],
  sortFunction: (a: any, b: any) => any
) => (Array.isArray(collection) ? collection.sort(sortFunction) : []);
