import React from 'react';
import { Create, SimpleForm, useCreate, useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { DIAMETERS_INDEX_URL } from '../../../core/constants/url.constants';
import { DiametersForm } from '../diametersForm/DiametersForm';
import { DiametersFormToolbar } from '../diametersForm/DiametersFormToolbar';
import { diametersFormValidator } from '../diametersForm/DiametersFormValidator';
import { convertFileToBase64 } from '../../../core/utils/file-utils';

export const DiametersCreate = () => {
  const [create] = useCreate('diameters');
  const notify = useNotify();
  const navigate = useNavigate();

  const diameterSave = async (data: any) => {
    data = {
      ...data,
      file: await convertFileToBase64(data.file.rawFile)
    };
    create(
      'diameters',
      { data },
      {
        onSuccess: () => {
          notify('resources.diameters.notifications.create.success', {
            type: 'success'
          });
          navigate(-1);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };
  return (
    <Create resource={'diameters'}>
      <SimpleForm
        onSubmit={diameterSave}
        validate={(e) => diametersFormValidator(e)}
        toolbar={<DiametersFormToolbar route={DIAMETERS_INDEX_URL} />}
      >
        <DiametersForm />
      </SimpleForm>
    </Create>
  );
};
