import Button from '@mui/material/Button';
import React from 'react';
import { useTranslate } from 'react-admin';

type Props = {
  onClick: () => void;
};

export const EfficiencyTestDataTablePlusCell = ({ onClick }: Props) => {
  const t = useTranslate();

  return (
    <td colSpan={9} style={{ textAlign: 'center' }}>
      <Button
        size="small"
        className="button-plus button-plus--cells"
        onClick={onClick}
        sx={{
          minWidth: 300
        }}
        tabIndex={-1}
      >
        {`+ ${t('resources.efficiency-tests.fields.addCells')}`}
      </Button>
    </td>
  );
};
