import {
  VALIDATION_NOT_EMAIL_TYPE,
  VALIDATION_REQUIRED
} from '../../../core/constants/validation.constants';
import { isValidEmail } from '../../../core/utils/validation-utils';

export function usersFormValidator(values: any) {
  const errors: any = {};

  if (!values.firstName) {
    errors.firstName = VALIDATION_REQUIRED;
  }
  if (!values.lastName) {
    errors.lastName = VALIDATION_REQUIRED;
  }
  if (!values.email) {
    errors.email = VALIDATION_REQUIRED;
  }
  if (!isValidEmail(values.email)) {
    errors.email = VALIDATION_NOT_EMAIL_TYPE;
  }

  return errors;
}
