import { Button, Card, CardActions, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import {
  BooleanInput,
  email,
  Form,
  Link,
  PasswordInput,
  required,
  TextInput,
  useLogin,
  useNotify,
  useTranslate
} from 'react-admin';
import { HeaderLogo } from '../components/shared/HeaderLogo';
import { RECOVERY_PASSWORD_URL } from '../core/constants/url.constants';
import { useLocalStorage } from '../core/hooks/useLocalStorage';

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [remindUser, setRemindUser] = useLocalStorage('remindUser', false);

  const t = useTranslate();
  const notify = useNotify();
  const login = useLogin();

  const handleSubmit = (values: FormValues) => {
    setLoading(true);
    login(values).catch(() => {
      setLoading(false);
      return notify('errors.login');
    });
  };

  return (
    <div id="login-page">
      <div className="login-container">
        <HeaderLogo />
        <Form onSubmit={handleSubmit} noValidate className="form-dark">
          <Box id="login-box">
            <Card
              variant="outlined"
              sx={{
                bgcolor: 'transparent',
                border: 0
              }}
            >
              <Box>
                <Box>
                  <TextInput
                    autoFocus
                    fullWidth
                    size="medium"
                    //variant="filled"
                    InputLabelProps={{ shrink: true }}
                    source="username"
                    label={t('ra.auth.username')}
                    disabled={loading}
                    validate={[required(), email()]}
                  />
                </Box>
                <Box>
                  <PasswordInput
                    fullWidth
                    margin="dense"
                    size="medium"
                    //variant="filled"
                    InputLabelProps={{ shrink: true }}
                    label="ra.auth.password"
                    source="password"
                    disabled={loading}
                    validate={required()}
                  />
                </Box>
                <Box sx={{ pl: '5px' }}>
                  <BooleanInput
                    value={remindUser}
                    label="resources.auth.remind_user"
                    source="remindUser"
                    onChange={(event) => setRemindUser(event.target.checked)}
                  />
                </Box>
              </Box>
              <CardActions
                sx={{
                  p: 0,
                  mt: 1
                }}
              >
                <Button
                  sx={{ color: 'theme.palette.primary' }}
                  variant="contained"
                  fullWidth
                  type="submit"
                  size="large"
                  color="secondary"
                  disabled={loading}
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                  {t('ra.auth.sign_in')}
                </Button>
              </CardActions>
              <CardActions
                sx={{
                  p: 0,
                  mt: 2
                }}
              >
                <Link
                  type="button"
                  to={RECOVERY_PASSWORD_URL}
                  sx={{ color: '#FFF' }}
                >
                  {t('resources.auth.recovery_password_button')}
                </Link>
              </CardActions>
            </Card>
          </Box>
        </Form>
      </div>
    </div>
  );
};

interface FormValues {
  email?: string;
  password?: string;
  remindUser?: boolean;
}
