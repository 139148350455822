import { Box, Button, Card, CardActions, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
  email,
  Form,
  required,
  TextInput,
  useNotify,
  useTranslate
} from 'react-admin';
import { Link, useNavigate } from 'react-router-dom';
import { HeaderLogo } from '../components/shared/HeaderLogo';
import { LOGIN_URL } from '../core/constants/url.constants';
import { recoverPasword } from '../core/services/auth-api.service';
import { getErrorMessageCode } from '../core/utils/error.utils';

export const PasswordRecovery = () => {
  const [loading, setLoading] = useState(false);
  const t = useTranslate();
  const notify = useNotify();
  const navigate = useNavigate();

  const onRecoveryPassword = (event: FormValues): void => {
    setLoading(true);
    recoverPasword(event.email!)
      .then((response) => {
        setLoading(false);
        const sucessKey =
          'resources.users.notifications.passwordRecovery.success';
        notify(sucessKey, { type: 'success' });
        navigate(LOGIN_URL);
      })
      .catch((error) => {
        setLoading(false);
        const code = getErrorMessageCode(error);
        notify(`errors.${code.toLowerCase()}`, { type: 'error' });
      });
  };

  return (
    <div id="login-page">
      <div className="login-container">
        <HeaderLogo />
        <Typography
          className="login-text"
          component="span"
          align="center"
          gutterBottom
        >
          {t('resources.auth.recovery_password.message')}
          <Link to={LOGIN_URL}>
            {t('resources.auth.recovery_password.link')}
          </Link>
        </Typography>
        <Form onSubmit={onRecoveryPassword} noValidate className="form-dark">
          <Box id="login-box">
            <Card
              variant="outlined"
              sx={{
                bgcolor: 'transparent',
                border: 0
              }}
            >
              <Box>
                <Box>
                  <TextInput
                    autoFocus
                    fullWidth
                    size="medium"
                    InputLabelProps={{ shrink: true }}
                    source="email"
                    label={t('ra.auth.email')}
                    disabled={loading}
                    validate={[required(), email()]}
                  />
                </Box>
              </Box>
              <CardActions
                sx={{
                  p: 0,
                  mt: 1
                }}
              >
                <Button
                  sx={{ color: 'theme.palette.primary' }}
                  variant="contained"
                  fullWidth
                  size="large"
                  color="secondary"
                  disabled={loading}
                  type="submit"
                >
                  {t('resources.auth.recovery_password.button')}
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Form>
      </div>
    </div>
  );
};

interface FormValues {
  email?: string;
}
