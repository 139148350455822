import React from 'react';
import { OutlinedInput } from '@mui/material';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';

type Props = {
  fields: any[];
  source: string;
  errors: any;
};

export const EditableCell = ({ fields, source, errors }: Props) => {
  const { register } = useFormContext();

  return (
    <>
      {fields.map((field, i) => (
        <Box key={field.id}>
          <OutlinedInput
            {...register(`${source}.${i}.value`)}
            sx={{
              fontSize: '13px',
              height: '22px',
              margin: '5px auto',
              minWidth: '50px',
              maxWidth: '65px',
              paddingLeft: '5px',
              '& .MuiInputBase-root': {
                height: '22px'
              },
              '& .MuiInputBase-input': {
                paddingRight: '0'
              },
              '& .MuiFormLabel-filled': {
                display: 'none'
              },
              '& legend': {
                display: 'none'
              },
              '& fieldset': {
                top: 0
              },
              '& .MuiAutocomplete-option': {
                backgroundColor: 'red'
              },
              '& .MuiFormLabel-root': {
                top: '-13px',
                fontSize: '13px',
                display: 'none'
              },
              '& .MuiFormLabel-root.Mui-focused': {
                display: 'none'
              },
              '& input[type=number]': {
                '-moz-appearance': 'textfield'
              },
              '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
              },
              '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
              }
            }}
            type="number"
            error={!!errors?.[i]}
            fullWidth
            inputProps={{ step: 0.01 }}
          />
        </Box>
      ))}
    </>
  );
};
