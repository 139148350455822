import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function TestIcon(props: any) {
  const propsMerged = {
    ...props,
    //viewPort: '20 20',
    viewBox: '-3 0 24 24'
  };
  return (
    <SvgIcon {...propsMerged}>
      <path d="M6 15.8c.541 0 1 .447 1 1v1.991c0 .553-.459 1-1 1H4c-.556 0-1-.447-1-1V16.8c0-.552.444-1 1-1zm0 1H4v1.991h2zm0-6.033c.541 0 1 .448 1 1v1.992c0 .552-.459 1-1 1H4c-.556 0-1-.448-1-1v-1.992c0-.552.444-1 1-1zm0 1H4v1.992h2zm0-5.964c.541 0 1 .448 1 1v1.992c0 .553-.459 1-1 1H4c-.556 0-1-.447-1-1V6.803c0-.552.444-1 1-1zm0 1H4v1.992h2zm5-2.023H7a2 2 0 0 1-1.935-1.493H2a.5.5 0 0 0-.5.5v16.426a.5.5 0 0 0 .5.5h14a.5.5 0 0 0 .5-.5V3.715a.5.5 0 0 0-.5-.5h-3c-.244.895-1 1.565-2 1.565zm1-4c.554 0 1 .448 1 1v.435h3a1.5 1.5 0 0 1 1.5 1.5v16.498c0 .787-.672 1.5-1.5 1.5H2c-.828 0-1.5-.713-1.5-1.5V3.787c0-.787.672-1.5 1.5-1.5h3V1.78a1 1 0 0 1 1-1zm0 1H6v1a1 1 0 0 0 1 1h4c.554 0 1-.448 1-1zm.507 15.566c.276 0 .493.224.493.5s-.217.5-.493.5h-4a.506.506 0 0 1-.507-.5c0-.276.23-.5.507-.5zm0-5.033c.276 0 .493.224.493.5 0 .277-.217.5-.493.5h-4a.506.506 0 0 1-.507-.5c0-.276.23-.5.507-.5zm0-4.963c.276 0 .493.224.493.5s-.217.5-.493.5h-4A.506.506 0 0 1 8 7.85c0-.276.23-.5.507-.5zm2 8.458c.276 0 .493.192.493.5 0 .276-.217.5-.493.5h-6a.506.506 0 0 1-.507-.5c0-.308.23-.5.507-.5zm0-5.033c.276 0 .493.225.493.5 0 .276-.217.5-.493.5h-6a.506.506 0 0 1-.507-.5c0-.275.23-.5.507-.5zm0-4.963c.276 0 .493.188.493.5 0 .276-.217.5-.493.5h-6a.506.506 0 0 1-.507-.5c0-.312.23-.5.507-.5z" />
    </SvgIcon>
  );
}
