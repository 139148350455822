import React from 'react';
import { Create, SimpleForm, useCreate, useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { PUMPS_INDEX_URL } from '../../../core/constants/url.constants';
import { PumpsForm } from '../pumpsForm/PumpsForm';
import { PumpsFormToolbar } from '../pumpsForm/PumpsFormToolbar';
import { pumpsFormValidator } from '../pumpsForm/PumpsFormValidator';

export const PumpsCreate = () => {
  const [create] = useCreate('pumps');
  const notify = useNotify();
  const navigate = useNavigate();

  const pumpSave = async (data: any) => {
    create(
      'pumps',
      { data },
      {
        onSuccess: () => {
          notify('resources.pumps.notifications.create.success', {
            type: 'success'
          });
          navigate(-1);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };
  return (
    <Create resource={'pumps'}>
      <SimpleForm
        onSubmit={pumpSave}
        validate={(e) => pumpsFormValidator(e)}
        toolbar={<PumpsFormToolbar route={PUMPS_INDEX_URL} />}
      >
        <PumpsForm />
      </SimpleForm>
    </Create>
  );
};
