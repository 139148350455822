import React from 'react';

import { Button, SaveButton, Toolbar, usePermissions } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { PrivilegeType } from '../../../core/models/privileges/privilegeType';
import { some } from '../../../core/utils/collection-utils';

export const UsersFormToolbar = ({ setSection, sectionList, route }: any) => {
  const navigate = useNavigate();
  const { permissions } = usePermissions();
  const clearFields = () => {
    if (route) {
      navigate(route);
    }
    if (setSection) {
      setSection(sectionList);
    }
  };

  return (
    <div>
      {some([PrivilegeType.MANAGE_USERS], permissions) && (
        <Toolbar>
          <Button
            variant="outlined"
            size="medium"
            onClick={() => clearFields()}
            label="shared.form.toolbar.actions.cancel"
          ></Button>
          <SaveButton label="shared.form.toolbar.actions.saveChanges" />
        </Toolbar>
      )}
    </div>
  );
};
