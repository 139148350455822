import { VALIDATION_REQUIRED } from '../../../../core/constants/validation.constants';

export function pumpsSpeedFormValidator(values: any) {
  const errors: any = {};

  if (!values.file) {
    errors.file = VALIDATION_REQUIRED;
  }

  return errors;
}
