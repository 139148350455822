import React from 'react';

export const HeaderLogo = () => {
  return (
    <div className="logo">
      <img
        src="src/images/logo-safem.png"
        alt="SAFEM"
        width="251"
        height="69"
      />
    </div>
  );
};
