import React, { ReactElement } from 'react';
import { Loading, usePermissions } from 'react-admin';
import { Navigate } from 'react-router';
import { LOGIN_URL } from '../../core/constants/url.constants';
import { PrivilegeType } from '../../core/models/privileges/privilegeType';
import jwtManager from '../../core/providers/jwtManager';
import { some } from '../../core/utils/collection-utils';

export const ProtectedRoute = ({
  privileges = [],
  redirectPath = '/',
  children = null
}: {
  privileges: PrivilegeType[];
  redirectPath: string;
  children?: ReactElement | null;
}) => {
  const { isLoading, permissions } = usePermissions();

  const token = jwtManager.getToken();

  if (!token) {
    return <Navigate to={LOGIN_URL} replace />;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!some(privileges, permissions)) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};
