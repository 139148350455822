import React from 'react';
import { Create, SimpleForm, useCreate, useNotify } from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';
import { PUMPS_INDEX_URL } from '../../../../core/constants/url.constants';
import { convertFileToBase64 } from '../../../../core/utils/file-utils';
import { PumpsSpeedForm } from '../pumpsSpeedForm/PumpsSpeedForm';
import { PumpsSpeedFormToolbar } from '../pumpsSpeedForm/PumpsSpeedFormToolbar';
import { pumpsSpeedFormValidator } from '../pumpsSpeedForm/PumpsSpeedFormValidator';

export const PumpsSpeedCreate = () => {
  const [create] = useCreate('pumps');
  const notify = useNotify();
  const navigate = useNavigate();
  const params = useParams();

  const pumpSpeedSave = async (data: any) => {
    data = {
      pumpHash: params?.pumpId,
      file: await convertFileToBase64(data.file.rawFile)
    };

    create(
      'pumps/import-pump-speed',
      { data },
      {
        onSuccess: () => {
          notify('resources.pumps.speeds.notifications.create.success', {
            type: 'success'
          });
          navigate(-1);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };
  return (
    <Create resource={'pumps'} title="resources.pumps.speeds.fields.create">
      <SimpleForm
        onSubmit={pumpSpeedSave}
        validate={(e) => pumpsSpeedFormValidator(e)}
        toolbar={<PumpsSpeedFormToolbar route={PUMPS_INDEX_URL} />}
      >
        <PumpsSpeedForm />
      </SimpleForm>
    </Create>
  );
};
