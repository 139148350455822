import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { useRecordContext } from 'react-admin';
import { EfficiencyTestsDeleteDialog } from './EfficiencyTestsDeleteDialog';

export const EfficiencyTestsDeleteButton = (props: any) => {
  const record: any = useRecordContext(props);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const handleDelete = () => setShowDeleteDialog(true);

  return (
    <Box component="span" className={props.className}>
      <IconButton onClick={() => handleDelete()}>
        <DeleteIcon />
      </IconButton>
      <EfficiencyTestsDeleteDialog
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        id={record.id}
      />
    </Box>
  );
};
