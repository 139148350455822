import React from 'react';
import { FileField, FileInput } from 'react-admin';

export const PumpsSpeedForm = () => {
  return (
    <div>
      <FileInput
        source="file"
        label="resources.pumps.speeds.fields.file"
        accept=".xls"
      >
        <FileField source="src" title="title" />
      </FileInput>
    </div>
  );
};
