import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { IconButton } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';

export const PumpsSpeedEnterButton = (props: any) => {
  const record: any = useRecordContext(props);
  const navigate = useNavigate();
  const params = useParams();

  function openPumpSpeed() {
    navigate(`/pumps/${params?.pumpId}/speeds/${record?.id}`);
  }

  return (
    <>
      <IconButton onClick={() => openPumpSpeed()}>
        <ArrowRightAltIcon />
      </IconButton>
    </>
  );
};
