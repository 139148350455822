import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function PrintIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <path d="M5.81 11.833h7.38v4.25H5.81zm-2.213-4.25c-.406 0-.738.319-.738.709 0 .39.332.708.738.708.407 0 .695-.318.695-.708 0-.39-.288-.709-.695-.709zm-.738-.708h13.282c.418 0 .737.307.737.708v5.667h-2.17v-2.833H4.292v2.833h-2.17V7.583c0-.4.32-.708.737-.708zm2.952-4.958h7.378v3.541H5.811zM4.29.5v4.958H2.86c-1.213 0-2.213.96-2.213 2.125v7.084h3.646V17.5h10.416v-2.833h3.646V7.583c0-1.165-1-2.125-2.213-2.125h-1.433V.5z" />
    </SvgIcon>
  );
}
