import React from 'react';
import {
  Create,
  SimpleForm,
  useLocaleState,
  useNotify,
  useRecordContext,
  useRefresh
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { saveFlowTestData } from '../../../../core/services/effieiciency-test-api.service';
import { EfficiencyTestDataSideBar } from './EfficiencyTestDataSideBar';
import { EfficiencyTestDataSubmitButton } from './EfficiencyTestDataSubmitButton';
import { EfficiencyTestDataTable } from './efficiencyTestDataTable/EfficiencyTestDataTable';
import { EfficiencyTestObservationsField } from './EfficiencyTestObservationsField';
import { efficiencyTestsDataFormValidator } from './EfficiencyTestsDataFormValidator';
import {
  transformTableDataFromApi,
  transformTableDataToApi
} from '../../../../core/utils/efficiency-test-utils';
import { EfficiencyTestDataHeader } from './EfficiencyTestDataHeader';
import useDiameters from '../../../../core/hooks/useDiameters';

export const EfficiencyTestForm = () => {
  const [locale] = useLocaleState();
  const params = useParams();
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const diameters = useDiameters();

  const onSubmitAllValues = async (values: any) => {
    try {
      await saveFlowTestData(
        params.efficiencyTestId,
        transformTableDataToApi(values)
      );
      notify('resources.efficiency-tests.notifications.save.success', {
        type: 'success'
      });
      refresh();
    } catch (error) {
      notify('resources.efficiency-tests.notifications.save.error', {
        type: 'warning'
      });
    }
  };

  return (
    <Create className="fullwidth-form" title=" ">
      <SimpleForm
        onSubmit={onSubmitAllValues}
        validate={efficiencyTestsDataFormValidator}
        toolbar={false}
        defaultValues={{
          flowTestValues: transformTableDataFromApi(
            record.efficiencyTestLines,
            locale,
            diameters.data ?? []
          )
        }}
      >
        <EfficiencyTestDataHeader record={record} />
        <div className="flexbox test-data">
          <EfficiencyTestDataTable />
          <EfficiencyTestDataSideBar record={record} />
        </div>
        <EfficiencyTestObservationsField record={record} />
        <EfficiencyTestDataSubmitButton record={record} />
      </SimpleForm>
    </Create>
  );
};
