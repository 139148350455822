import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import {
  NumberInput,
  RadioButtonGroupInput,
  RaRecord,
  useLocaleState
} from 'react-admin';
import { getCurrentTypes } from '../../../../core/services/current-type-api.service';

export const EfficiencyTestDataSideBar = (props: { record: RaRecord }) => {
  const record = props.record;
  const [currentTypeChoices, setCurrentTypeChoices] = useState([]);
  const [locale] = useLocaleState();

  useEffect(() => {
    getCurrentTypeChoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentTypeChoices = async () => {
    const currentTypes = await getCurrentTypes(locale);
    const transform = currentTypes?.data.map((type: any) => {
      return { id: type?.code, name: type?.name };
    });
    setCurrentTypeChoices(transform);
  };

  return (
    <Box className="test-data__sidebar">
      <div style={{ display: 'block' }}>
        <div>
          <RadioButtonGroupInput
            source="currentCode"
            label="resources.efficiency-tests.fields.currentType"
            choices={currentTypeChoices}
            defaultValue={record?.current?.code}
          />
        </div>
        <div>
          <NumberInput
            source="voltage"
            label="resources.efficiency-tests.fields.voltage"
            defaultValue={record?.voltage}
            required
          />
        </div>
        <div>
          <NumberInput
            source="tWater"
            label="resources.efficiency-tests.fields.waterTemp"
            defaultValue={record?.waterTemperature}
            required
          />
        </div>
        <div>
          <NumberInput
            source="paMmbar"
            label="resources.efficiency-tests.fields.pa"
            defaultValue={record?.paMma}
            required
          />
        </div>
        <div>
          <NumberInput
            source="pumpRpm"
            label="resources.efficiency-tests.fields.pumpRpm"
            defaultValue={record?.pumpRpm}
            required
          />
        </div>
        <div>
          <NumberInput
            source="motorKw"
            label="resources.efficiency-tests.fields.motorKw"
            defaultValue={record?.motorKw}
          />
        </div>
        <div>
          <NumberInput
            source="aspirationTemperature"
            label="resources.efficiency-tests.fields.aspirationTemperature"
            defaultValue={record?.aspirationTemperature}
            required
          />
        </div>
        <div>
          <NumberInput
            source="effFactor"
            label="resources.efficiency-tests.fields.effFactor"
            defaultValue={record?.effFactor}
            required
          />
        </div>
        <div>
          <NumberInput
            source="waterFlowM3H"
            label="resources.efficiency-tests.fields.waterFlow"
            defaultValue={record?.waterFlow}
            required
          />
        </div>
        <div>
          <NumberInput
            source="flowPercentage"
            label="resources.efficiency-tests.fields.flowPercentaje"
            min={0}
            defaultValue={record?.flowPercentage ?? 100}
            step={0}
          />
        </div>
        <div>
          <NumberInput
            source="motorRpm"
            label="resources.efficiency-tests.fields.motorRpm"
            defaultValue={record?.motorRpm}
          />
        </div>
      </div>
    </Box>
  );
};
