import axiosInstance, {
  getDefaultAnonymousRequestConfig,
  getDefaultAnonymousRequestConfigWithErrorHandling
} from './base/axios.client';

const DEFAULT_ENTRY_POINT = 'auth';

export async function recoverPasword(email: string): Promise<any> {
  const postBody = { email };
  const response = await axiosInstance.post(
    `${DEFAULT_ENTRY_POINT}/recover-password`,
    postBody,
    getDefaultAnonymousRequestConfigWithErrorHandling()
  );

  return response;
}

export async function resetPassword(
  hash: string,
  password: string,
  passwordRepeat: string
): Promise<any> {
  const postBody = { hash, password, passwordRepeat };
  const response = await axiosInstance.post(
    `${DEFAULT_ENTRY_POINT}/reset-password`,
    postBody,
    getDefaultAnonymousRequestConfig()
  );

  return response;
}

export async function generatePassword(
  hash: string,
  password: string,
  passwordRepeat: string
): Promise<any> {
  const postBody = { hash, password, passwordRepeat };
  const response = await axiosInstance.post(
    `${DEFAULT_ENTRY_POINT}/generate-password`,
    postBody,
    getDefaultAnonymousRequestConfig()
  );

  return response;
}
