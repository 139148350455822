import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';

export const UserEnterButton = (props: any) => {
  const record: any = useRecordContext(props);
  const navigate = useNavigate();

  function open() {
    navigate(`/users/${record.id}`);
  }

  return (
    <Box component="span" className={props.className}>
      <IconButton onClick={() => open()}>
        <ArrowRightAltIcon />
      </IconButton>
    </Box>
  );
};
