import { mapCollection } from './collection-utils';
import { toLocaleString } from './number-utils';
import _ from 'lodash';

export const transformTableDataFromApi = (lines, locale, diameters) => {
  if (lines?.length === 0) {
    return [EfficiencyTestTableInitialData(diameters)];
  }
  return mapCollection(
    (line: any) => ({
      id: line?.id,
      m3min: toLocaleString(line?.m3Min, locale),
      diameters: extractSubLineFromList(
        line?.efficiencyTestLineDatas,
        'diameter.id'
      ),
      mmHgLt: [{ value: line?.mmHgLt }],
      mmHgLc: [{ value: line?.mmHgLc }],
      holesOpenLt: extractSubLineFromList(
        line?.efficiencyTestLineDatas,
        'holeOpenLt'
      ),
      holesOpenLc: extractSubLineFromList(
        line?.efficiencyTestLineDatas,
        'holeOpenLc'
      ),
      a: [{ value: line?.ampere }],
      cos: [{ value: line?.cos }],
      kwCurve: toLocaleString(line?.kwCurve, locale)
    }),
    lines
  );
};

const extractSubLineFromList = (list: any, accesor: string) => {
  if (list && list.length > 0) {
    return mapCollection((item: any) => {
      return { value: _.get(item, accesor) };
    }, list);
  }
  return [emptyCell()];
};

const emptyCell = (defaultValue?) => ({ value: defaultValue ?? '' });

export const EfficiencyTestTableInitialData = (diameters) => {
  const defaultCos = 0.8;
  const defaultMiniRowsCount = 15;
  const defaultHolesOpenValues = 0;
  const defaultDiameterValues = [
    0.1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14
  ];

  return {
    m3min: '',
    diameters: defaultDiameterValues.map((diameter) => {
      const value = diameters.find((obj) => obj.diameter === diameter);
      return emptyCell(value?.id);
    }),
    mmHgLt: [emptyCell()],
    mmHgLc: [emptyCell()],
    holesOpenLt: Array(defaultMiniRowsCount).fill(
      emptyCell(defaultHolesOpenValues)
    ),
    holesOpenLc: Array(defaultMiniRowsCount).fill(
      emptyCell(defaultHolesOpenValues)
    ),
    a: [emptyCell()],
    cos: [emptyCell(defaultCos)],
    kwCurve: ''
  };
};

export const transformTableDataToApi = (data) => ({
  pumpHash: data.pumpHash,
  flowPercentage: data.flowPercentage,
  paMmbar: data.paMmbar,
  pumpRpm: data.pumpRpm,
  tWater: data.tWater,
  voltage: data.voltage,
  waterFlowM3H: data.waterFlowM3H,
  motorKw: data.motorKw,
  aspirationTemperature: data.aspirationTemperature,
  effFactor: data.effFactor,
  motorRpm: data.motorRpm,
  observations: data.observations,
  flowTestValues: data.flowTestValues.map(transformFlowTestValuesToApi),
  currentCode: data.currentCode
});

const transformFlowTestValuesToApi = (data) => ({
  diameters: data.diameters.map((d) => d.value),
  mmHgLt: data.mmHgLt.map((lt) => lt.value),
  mmHgLc: data.mmHgLc.map((lc) => lc.value),
  holesOpenLt: data.holesOpenLt.map((lt) => lt.value),
  holesOpenLc: data.holesOpenLc.map((lc) => lc.value),
  a: data.a.map((i) => i.value),
  cos: data.cos.map((c) => c.value)
});
