import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function UsersIcon(props: any) {
  const propsMerged = {
    ...props,
    //viewPort: '20 20',
    viewBox: '-2 -2 22 22'
  };
  return (
    <SvgIcon {...propsMerged}>
      <path d="M.53 14.174C.53 11.42 3.835 8 7.698 8c1.366.064 2.703.462 3.83 1.203a4.927 4.927 0 0 1 2.119-.52c2.63 0 4.881 2.439 4.881 4.434 0 2.223-2.66 2.476-4.051 2.533-1.026 1.64-3.949 1.9-6.78 1.9-3.544 0-7.169-.399-7.169-3.376zm1.22 0c0 1.476 1.836 2.11 5.949 2.11 4.118 0 5.949-.634 5.949-2.11 0-1.919-2.667-4.857-5.95-4.857-3.282 0-5.948 2.957-5.948 4.857zm13.118 0v.171c2.44-.183 2.44-.93 2.44-1.228 0-1.267-1.64-3.167-3.66-3.167-.308.05-.614.05-.91.133 1.306 1.184 2.13 2.71 2.13 4.091zm-2.73-6.773c-.4-.401-.609-.98-.609-1.568 0-.588.208-1.151.609-1.567a2.081 2.081 0 0 1 3.02 0c.371.416.625.98.625 1.567 0 .588-.254 1.167-.625 1.568-.4.415-.944.599-1.51.599-.567 0-1.119-.184-1.51-.6zM13 5.16a.969.969 0 0 0-.269.672c0 .252.097.494.269.672a.899.899 0 0 0 .647.278c.242 0 .475-.1.647-.278.172-.178.234-.42.234-.672 0-.252-.063-.493-.234-.671a.97.97 0 0 0-.647-.279.97.97 0 0 0-.647.279zm-7.612.695c-.572-.594-.86-1.4-.86-2.24 0-.84.288-1.616.86-2.238A2.986 2.986 0 0 1 7.529.45c.826 0 1.602.334 2.174.928.572.622.894 1.399.894 2.239 0 .84-.322 1.645-.894 2.239a3.005 3.005 0 0 1-2.174.927 2.986 2.986 0 0 1-2.14-.927zm.863-3.583c-.344.356-.536.84-.536 1.344 0 .504.192.987.536 1.383.343.316.808.517 1.277.517.503 0 1-.2 1.311-.517.344-.396.537-.88.537-1.383 0-.504-.193-.988-.537-1.344-.31-.356-.808-.556-1.31-.556-.47 0-.935.2-1.278.556z" />
    </SvgIcon>
  );
}
