import {
  VALIDATION_MAX_THREE_DECIMALS,
  VALIDATION_MORE_THAN_ZERO,
  VALIDATION_REQUIRED
} from '../../../core/constants/validation.constants';
import { countDecimals } from '../../../core/utils/number-utils';

export function diametersFormValidator(values: any) {
  const errors: any = {};

  if (!values.diameter) {
    errors.diameter = VALIDATION_REQUIRED;
  }
  if (values.diameter <= 0) {
    errors.diameter = VALIDATION_MORE_THAN_ZERO;
  }
  if (countDecimals(values.diameter) > 3) {
    errors.diameter = VALIDATION_MAX_THREE_DECIMALS;
  }

  if (!values.file) {
    errors.file = VALIDATION_REQUIRED;
  }

  return errors;
}
