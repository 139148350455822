import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Chart, ChartSerieType } from '../../../../components/shared/Chart';
import {
  CURVES_COLOR,
  CURVES_NAME,
  TEST_COLOR,
  TEST_NAME
} from '../../../../core/constants/efficiency-test-curves.constants';
import {
  isEmpty,
  mapCollection
} from '../../../../core/utils/collection-utils';
import { EfficiencyTestsDownloadResultsPdfButton } from '../../efficiencyTestsShared/EfficienctTestsDownloadResultsPdfButton';

export const EfficiencyTestCurvesCharts = () => {
  const record = useRecordContext();
  const { efficiencyTestResults } = record;
  const t = useTranslate();
  const isButtonDisabled = isEmpty(efficiencyTestResults);

  const flowComparationSeries = [
    {
      name: TEST_NAME,
      color: TEST_COLOR,
      data: mapCollection(
        (line: any) => ({
          x: line.comparationX,
          y: line.flowComparationTestY
        }),
        efficiencyTestResults
      )
    },
    {
      name: CURVES_NAME,
      color: CURVES_COLOR,
      data: mapCollection(
        (line: any) => ({
          x: line.comparationX,
          y: line.flowComparationCurveY
        }),
        efficiencyTestResults
      )
    }
  ];

  const consumeComparationSeries = [
    {
      name: TEST_NAME,
      color: TEST_COLOR,
      data: mapCollection(
        (line: any) => ({
          x: line.comparationX,
          y: line.consumeComparationTestY
        }),
        efficiencyTestResults
      )
    },
    {
      name: CURVES_NAME,
      color: CURVES_COLOR,
      data: mapCollection(
        (line: any) => ({
          x: line.comparationX,
          y: line.consumeComparationCurveY
        }),
        efficiencyTestResults
      )
    }
  ];

  return (
    <>
      <div className="print-buttons">
        <EfficiencyTestsDownloadResultsPdfButton
          disabled={isButtonDisabled}
          efficiencyTest={record}
        />
      </div>
      <Chart
        title={t('resources.efficiency-tests.fields.flowComparation')}
        xTitle="mbar"
        yTitle="m&#179;/h"
        series={flowComparationSeries as ChartSerieType[]}
        axisSteps={{ x: 20, y: 200 }}
      />
      <Chart
        title={t('resources.efficiency-tests.fields.consumeComparation')}
        xTitle="mbar"
        yTitle="kw/m&#179;/min"
        series={consumeComparationSeries as ChartSerieType[]}
        axisSteps={{ x: 20, y: 0.5 }}
      />
    </>
  );
};
