import { VALIDATION_REQUIRED } from '../../../core/constants/validation.constants';

export function pumpsFormValidator(values: any) {
  const errors: any = {};

  if (!values.model) {
    errors.model = VALIDATION_REQUIRED;
  }

  if (!values.manufacturer) {
    errors.manufacturer = VALIDATION_REQUIRED;
  }

  return errors;
}
