import React, { useEffect, useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Result } from '../../../../core/models/efficiency-tests/result';
import { ResultHeaderItem } from '../../../../core/models/efficiency-tests/result-header-item';
import {
  isEmpty,
  mapCollection
} from '../../../../core/utils/collection-utils';
import { EfficiencyTestsDownloadResultsPdfButton } from '../../efficiencyTestsShared/EfficienctTestsDownloadResultsPdfButton';
import { EfficiencyTestResultsHeaderTable } from './EfficiencyTestResultsHeaderTable';
import { EfficiencyTestResultsMainTable } from './EfficiencyTestResultsMainTable';

export const EfficiencyTestResults = () => {
  const t = useTranslate();
  const [dataFirst, setDataFirst] = useState<ResultHeaderItem[]>([]);
  const [dataSecond, setDataSecond] = useState<ResultHeaderItem[]>([]);
  const [results, setResults] = useState<Result[]>([]);
  const record = useRecordContext();
  const {
    satref,
    identifier,
    waterTemperature,
    pumpRpm,
    pump,
    paMma,
    voltage,
    ringQ,
    efficiencyTestResults,
    motorKw,
    aspirationTemperature,
    effFactor,
    motorRpm
  } = record;

  const isButtonDisabled = isEmpty(efficiencyTestResults);

  useEffect(() => {
    const headerDataFirst = [
      {
        title: t('resources.efficiency-tests.fields.satref'),
        value: satref ?? '-'
      },
      {
        title: t('resources.efficiency-tests.fields.model'),
        value: pump.pumpName ?? '-'
      },
      {
        title: t('resources.efficiency-tests.fields.pumpRpm'),
        value: pumpRpm ?? '-'
      },
      {
        title: t('resources.efficiency-tests.fields.identifier'),
        value: identifier ?? '-'
      }
    ];
    setDataFirst(headerDataFirst);

    const headerDataSecond = [
      {
        title: t('resources.efficiency-tests.fields.ambientPressure'),
        value: paMma ?? '-'
      },
      {
        title: t('resources.efficiency-tests.fields.ringQ'),
        value: ringQ ?? '-'
      },
      {
        title: t('resources.efficiency-tests.fields.waterTemp'),
        value: waterTemperature ?? '-'
      },
      {
        title: t('resources.efficiency-tests.fields.motorKw'),
        value: motorKw ?? '-'
      },
      {
        title: t('resources.efficiency-tests.fields.tension'),
        value: voltage ?? '-'
      },
      {
        title: t('resources.efficiency-tests.fields.aspirationTemp'),
        value: aspirationTemperature ?? '-'
      },
      {
        title: t('resources.efficiency-tests.fields.motorRpm'),
        value: motorRpm ?? '-'
      },
      {
        title: t('resources.efficiency-tests.fields.effFactor'),
        value: effFactor ?? '-'
      }
    ];
    setDataSecond(headerDataSecond);

    const resultsTableData: Result[] = mapCollection((line: any) => {
      return {
        suctionPressure: {
          lt: line.suctionPressureLt,
          lc: line.suctionPressureLc
        },
        flow: {
          measurement: line.flowMeasurement,
          curve: line.flowCurve,
          difference: line.flowDifference
        },
        absorbedPower: {
          measurement: line.absorbedPowerMeasurement,
          curve: line.absorbedPowerCurve,
          difference: line.absorbedPowerDifference
        }
      };
    }, efficiencyTestResults);
    setResults(resultsTableData);
    // eslint-disable-next-line
  }, [record]);

  return (
    <>
      <div className="print-buttons">
        <EfficiencyTestsDownloadResultsPdfButton
          disabled={isButtonDisabled}
          efficiencyTest={record}
        />
      </div>
      <EfficiencyTestResultsHeaderTable
        dataFirst={dataFirst}
        dataSecond={dataSecond}
      />
      <EfficiencyTestResultsMainTable results={results} />
    </>
  );
};
