const checkEnvironmentVariable = (
  environmentVariable: any,
  name: string
): string | undefined => {
  if (!environmentVariable) {
    throw new Error(`Couldn't find environment variable: ${name}`);
  } else {
    return environmentVariable;
  }
};

export const envConfig = {
  baseUrl: `${checkEnvironmentVariable(
    process.env.REACT_APP_API_URL,
    'API_URL'
  )}/`,
  env: process.env.REACT_APP_ENV // TODO: use process.env.NODE_ENV
};
