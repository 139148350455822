import React from 'react';
import {
  Datagrid,
  List,
  TextField,
  usePermissions,
  useTranslate
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { EmptyList } from '../../../../components/shared/EmptyList';
import { ListHeader } from '../../../../components/shared/ListHeader';
import { TableCell } from '../../../../components/shared/TableCell';
import { PER_PAGE } from '../../../../core/constants/pagination.constants';
import { PrivilegeType } from '../../../../core/models/privileges/privilegeType';
import { some } from '../../../../core/utils/collection-utils';
import { PumpsSpeedCreateButton } from './PumpsSpeedCreateButton';
import { PumpsSpeedDeleteButton } from './PumpsSpeedDeleteButton';
import { PumpsSpeedEnterButton } from './PumpsSpeedEnterButton';
import { PumpsSpeedListActions } from './PumpsSpeedListActions';

export const PumpsSpeedList = () => {
  const params = useParams();
  const { permissions } = usePermissions();
  const t = useTranslate();
  const hasDeletePermissions = some([PrivilegeType.MANAGE_PUMPS], permissions);
  return (
    <List
      resource={`pumps/${params.pumpId}/speeds`}
      actions={<PumpsSpeedListActions />}
      empty={
        <EmptyList title={t('shared.list.empty.title')}>
          <PumpsSpeedCreateButton />
        </EmptyList>
      }
      debounce={200}
      perPage={PER_PAGE}
      title="resources.pumps.fields.speedsList"
    >
      <Datagrid
        bulkActionButtons={false}
        header={
          <ListHeader
            columns={
              <>
                <TableCell source="speed">
                  {t('resources.pumps.speeds.fields.speed')}
                </TableCell>
              </>
            }
            deleteColumn={hasDeletePermissions}
            enterColumn={true}
          />
        }
      >
        <TextField source="speed" />
        {hasDeletePermissions && <PumpsSpeedDeleteButton />}
        <PumpsSpeedEnterButton />
      </Datagrid>
    </List>
  );
};
