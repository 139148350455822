import React from 'react';
import { FileField, FileInput, NumberInput, required } from 'react-admin';

export const DiametersForm = () => {
  return (
    <>
      <div>
        <NumberInput
          source="diameter"
          validate={[required()]}
          label="resources.diameters.fields.diameter"
        />
      </div>
      <div>
        <FileInput
          source="file"
          label="resources.diameters.flows.fields.file"
          accept=".xls,.xlsx"
          validate={[required()]}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </div>
    </>
  );
};
