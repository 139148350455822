import { isNullOrUndefined } from './object-utils';

export const capitalize = (value: string) => {
  return value ? value.charAt(0).toUpperCase() + value.slice(1) : value;
};

const reduceCapitalized = (acc: string, word: string) =>
  acc.concat(' ', capitalize(word));

export const capitalizeAll = (value: string) => {
  return value ? value.split(' ').reduce(reduceCapitalized, '') : value;
};

export const matches = (haystack: string, needle: string) => {
  const lowHaystack = haystack ? haystack.toLowerCase() : '';
  const lowNeedle = needle ? needle.toLowerCase() : '';

  return !lowNeedle || (lowHaystack && lowHaystack.includes(lowNeedle));
};

export const searchText = (text: string | null | undefined, query: string) => {
  if (!text) {
    return false;
  }

  return matches(text.replace(/\s/g, ''), query.replace(/\s/g, ''));
};

export const compare = (a: string, b: string) => a.localeCompare(b);

export const underscore = (value: string) =>
  value ? value.replace(' ', '_') : value;

export const valueOf = (value: Object) =>
  isNullOrUndefined(value) ? null : value.toString();

export const isEmpty = (value: string) =>
  isNullOrUndefined(value) || !value.trim();

export const snakeToCamel = (value: string) =>
  value.replace(/[_]([a-z])/gi, (_, $1) => $1.toUpperCase());
