import React from 'react';
import {
  Datagrid,
  DateField,
  List,
  TextField,
  usePermissions,
  useTranslate
} from 'react-admin';
import { EmptyList } from '../../../components/shared/EmptyList';
import { ListHeader } from '../../../components/shared/ListHeader';
import { TableCell } from '../../../components/shared/TableCell';
import { PER_PAGE } from '../../../core/constants/pagination.constants';
import { PrivilegeType } from '../../../core/models/privileges/privilegeType';
import { some } from '../../../core/utils/collection-utils';
import { EfficiencyTestEnterButton } from './EfficiencyTestEnterButton';
import { EfficiencyTestsDeleteButton } from './EfficiencyTestsDeleteButton';
import { EfficiencyTestsListActions } from './EfficiencyTestsListActions';

export const EfficiencyTestsList = () => {
  const { permissions } = usePermissions();
  const t = useTranslate();
  const hasDeletePermissions = some(
    [PrivilegeType.MANAGE_DIAMETERS],
    permissions
  );

  return (
    <List
      resource="efficiency-tests/paginated"
      actions={<EfficiencyTestsListActions />}
      empty={
        <EmptyList title={t('shared.list.empty.title')}>
          <EfficiencyTestsListActions />
        </EmptyList>
      }
      perPage={PER_PAGE}
      sort={{ field: 'testNumber', order: 'DESC' }}
      title={t('resources.efficiency-tests.name', 2)}
    >
      <Datagrid
        bulkActionButtons={false}
        header={
          <ListHeader
            columns={
              <>
                <TableCell source="testNumber">
                  {t('resources.efficiency-tests.fields.testNumber')}
                </TableCell>
                <TableCell source="date">
                  {t('resources.efficiency-tests.fields.date')}
                </TableCell>
                <TableCell source="identifier">
                  {t('resources.efficiency-tests.fields.identifier')}
                </TableCell>
                <TableCell source="pump.pumpName">
                  {t('resources.efficiency-tests.fields.pumpModel')}
                </TableCell>
                <TableCell source="customer.name">
                  {t('resources.efficiency-tests.fields.client')}
                </TableCell>
                <TableCell source="orderNumber">
                  {t('resources.efficiency-tests.fields.orderNumber')}
                </TableCell>
              </>
            }
            deleteColumn={hasDeletePermissions}
            enterColumn={true}
          />
        }
      >
        <TextField source="testNumber" />
        <DateField source="date" />
        <TextField source="identifier" />
        <TextField source="pump.pumpName" />
        <TextField source="customer.name" />
        <TextField source="orderNumber" />
        {hasDeletePermissions && <EfficiencyTestsDeleteButton />}
        <EfficiencyTestEnterButton />
      </Datagrid>
    </List>
  );
};
