import React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { CHART_MARGIN } from '../../core/constants/chart.constants';
import {
  findElementByPropertyValue,
  mapCollection,
  maxValue,
  minValue,
  push,
  reduce,
  sort
} from '../../core/utils/collection-utils';
import { Box, Typography } from '@mui/material';
import { roundDown, roundUp } from '../../core/utils/number-utils';

interface ChartProps {
  title: string;
  xTitle: string;
  yTitle: string;
  series: ChartSerieType[];
  axisSteps: { x: number; y: number };
}

export interface ChartSerieType {
  name: string;
  color: string;
  data: { x: number; y: number }[];
}

export const Chart = (props: ChartProps) => {
  const points = reduce(
    (acc: any, curr: any) => {
      mapCollection((point: any) => {
        if (!findElementByPropertyValue(acc, 'x', point.x)) {
          const coordinates = { x: point.x } as any;
          mapCollection(
            (serie, index) =>
              (coordinates['y' + index] = findElementByPropertyValue(
                serie.data,
                'x',
                point.x
              ).y),
            props.series
          );
          acc = push(acc, coordinates);
        }
      }, curr.data);
      return sort(acc, (a: any, b: any) => a.x - b.x);
    },
    [],
    props.series
  ) as any[];

  const min = (axis: string) =>
    roundDown(
      minValue(
        mapCollection(
          (serie) =>
            minValue(mapCollection((point) => point[axis], serie.data)),
          props.series
        )
      ),
      props.axisSteps[axis]
    );

  const max = (axis: string) =>
    roundUp(
      maxValue(
        mapCollection(
          (serie) =>
            maxValue(mapCollection((point) => point[axis], serie.data)),
          props.series
        )
      ),
      props.axisSteps[axis]
    );

  const calculateChartDomain =
    (axis: string) =>
    ([dataMin, dataMax]) => {
      const domainMin = roundDown(dataMin, props.axisSteps[axis]);
      const domainMax = roundUp(dataMax, props.axisSteps[axis]);
      return [domainMin, domainMax] as [number, number];
    };

  const calculateTicks = (
    start: number,
    end: number,
    step: number
  ): number[] => {
    let ticks = [];
    for (let i = start; i <= end; i = i + step) {
      ticks.push(i);
    }
    return ticks;
  };

  return (
    <Box className="custom-chart">
      <Typography variant="h6" component="h3">
        {props.title}
      </Typography>
      <ResponsiveContainer width="95%" height={400}>
        <LineChart width={400} height={400} data={points}>
          {props.series.map((serie, index) => (
            <Line
              key={serie.name}
              type="monotone"
              dataKey={`y${index}`}
              name={serie.name}
              stroke={serie.color}
              dot={false}
              strokeWidth={2}
              legendType="plainline"
            />
          ))}
          <XAxis
            type="number"
            label={{
              value: props.xTitle,
              position: 'insideBottom',
              offset: -5
            }}
            dataKey="x"
            domain={calculateChartDomain('x')}
            padding={{ left: CHART_MARGIN, right: CHART_MARGIN }}
            ticks={calculateTicks(min('x'), max('x'), props.axisSteps['x'])}
          />
          <YAxis
            type="number"
            label={{
              value: props.yTitle,
              angle: -90,
              position: 'insideLeft',
              offset: 5
            }}
            domain={calculateChartDomain('y')}
            padding={{ top: CHART_MARGIN, bottom: CHART_MARGIN }}
            ticks={calculateTicks(min('y'), max('y'), props.axisSteps['y'])}
          />
          <Tooltip />
          <Legend verticalAlign="top" align="right" height={35} />
          <CartesianGrid strokeDasharray="3 3" />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
