import axiosInstance, { getDefaultRequestConfig } from './base/axios.client';

export async function getDataPdf(hash: string): Promise<any> {
  return await axiosInstance.get(
    `/efficiency-tests/${hash}/pdf/data`,
    getDefaultRequestConfig()
  );
}

export async function getResultsPdf(hash: string): Promise<any> {
  return await axiosInstance.get(
    `/efficiency-tests/${hash}/pdf/results`,
    getDefaultRequestConfig()
  );
}

export async function getMergedPdf(hash: string): Promise<any> {
  return await axiosInstance.get(
    `/efficiency-tests/${hash}/pdf/merged`,
    getDefaultRequestConfig()
  );
}

export async function saveFlowTestData(
  efficiencyTestId: string,
  flowTestData: any
): Promise<any> {
  return await axiosInstance.post(
    `/efficiency-tests/${efficiencyTestId}/flow-test-data`,
    {
      ...flowTestData
    },
    getDefaultRequestConfig()
  );
}
