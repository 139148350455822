import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import {
  useCreate,
  useCreateSuggestionContext,
  useTranslate
} from 'react-admin';

export const EfficiencyTestCreateCustomer = (props: {
  refreshCustomers: () => void;
}) => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate();
  const [value, setValue] = React.useState(filter || '');
  const t = useTranslate();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    create(
      'customers',
      { data: { name: value } },
      {
        onSuccess: (data) => {
          props.refreshCustomers();
          setValue(data.id);
          onCreate(data);
        }
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <span>
            {t('resources.efficiency-tests.customers.create.title')}: {value}
          </span>
        </DialogContent>
        <DialogActions>
          <Button type="submit">
            {t('shared.form.toolbar.actions.accept')}
          </Button>
          <Button onClick={onCancel}>
            {t('shared.form.toolbar.actions.cancel')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
