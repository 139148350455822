import React from 'react';
import { Create, SimpleForm, useCreate, useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { EFFICIENCY_TESTS_INDEX_URL } from '../../../core/constants/url.constants';
import { EfficiencyTestsForm } from '../efficiencyTestsForm/EfficiencyTestsForm';
import { EfficiencyTestsFormToolbar } from '../efficiencyTestsForm/EfficiencyTestsFormToolbar';
import { efficiencyTestsFormValidator } from '../efficiencyTestsForm/EfficiencyTestsFormValidator';

export const EfficiencyTestsCreate = () => {
  const [create] = useCreate('pumps');
  const notify = useNotify();
  const navigate = useNavigate();

  const efficiencyTestSaveSave = async (data: any) => {
    data = { ...data, date: new Date(data.date).getTime() };

    create(
      'efficiency-tests',
      { data },
      {
        onSuccess: () => {
          notify('resources.efficiency-tests.notifications.create.success', {
            type: 'success'
          });
          navigate(-1);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };
  return (
    <Create resource={'efficiency-tests'}>
      <SimpleForm
        onSubmit={efficiencyTestSaveSave}
        validate={(e) => efficiencyTestsFormValidator(e)}
        toolbar={
          <EfficiencyTestsFormToolbar route={EFFICIENCY_TESTS_INDEX_URL} />
        }
      >
        <EfficiencyTestsForm />
      </SimpleForm>
    </Create>
  );
};
