import React from 'react';
import {
  Datagrid,
  List,
  TextField,
  usePermissions,
  useTranslate
} from 'react-admin';
import { EmptyList } from '../../../components/shared/EmptyList';
import { ListHeader } from '../../../components/shared/ListHeader';
import { TableCell } from '../../../components/shared/TableCell';
import { PER_PAGE } from '../../../core/constants/pagination.constants';
import { PrivilegeType } from '../../../core/models/privileges/privilegeType';
import { some } from '../../../core/utils/collection-utils';
import { UserDeleteButton } from './UserDeleteButton';
import { UserEnterButton } from './UserEnterButton';
import { UsersListActions } from './UsersListActions';

export const UsersList = () => {
  const { permissions } = usePermissions();
  const t = useTranslate();
  const hasDeletePermissions = some([PrivilegeType.MANAGE_USERS], permissions);
  return (
    <List
      resource={'users'}
      actions={<UsersListActions />}
      empty={
        <EmptyList title={t('shared.list.empty.title')}>
          <UsersListActions />
        </EmptyList>
      }
      debounce={200}
      perPage={PER_PAGE}
      title={t('resources.users.name', 2)}
    >
      <Datagrid
        bulkActionButtons={false}
        className="users-table"
        header={
          <ListHeader
            columns={
              <>
                <TableCell source="firstName">
                  {t('resources.users.fields.firstName')}
                </TableCell>
                <TableCell source="lastName">
                  {t('resources.users.fields.lastName')}
                </TableCell>
                <TableCell source="secondLastName">
                  {t('resources.users.fields.secondLastName')}
                </TableCell>
                <TableCell source="email">
                  {t('resources.users.fields.email')}
                </TableCell>
                <TableCell source="phone">
                  {t('resources.users.fields.phone')}
                </TableCell>
              </>
            }
            deleteColumn={hasDeletePermissions}
            enterColumn={true}
          />
        }
      >
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="secondLastName" />
        <TextField source="email" />
        <TextField source="phone" />
        {hasDeletePermissions && <UserDeleteButton className="cell-action" />}
        <UserEnterButton className="cell-action" />
      </Datagrid>
    </List>
  );
};
