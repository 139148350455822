import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { UserDeleteDialog } from './UserDeleteDialog';

export const UserDeleteButton = (props: any) => {
  const record: any = useRecordContext(props);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const handleDeleteDiameter = () => setShowDeleteDialog(true);

  return (
    <Box component="span" className={props.className}>
      <IconButton onClick={() => handleDeleteDiameter()}>
        <DeleteIcon />
      </IconButton>
      <UserDeleteDialog
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        id={record.id}
      />
    </Box>
  );
};
