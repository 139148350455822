import React from 'react';
import { Button, RaRecord } from 'react-admin';

export const EfficiencyTestDataSubmitButton = (props: { record: RaRecord }) => {
  return (
    <Button
      sx={{ margin: '20px 20px 20px 8px' }}
      type="submit"
      color="primary"
      variant="contained"
      size="medium"
      label="shared.form.toolbar.actions.save"
    ></Button>
  );
};
