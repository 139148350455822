import React from 'react';
import { required, TextInput } from 'react-admin';

export const PumpsForm = () => {
  return (
    <div>
      <TextInput
        source="model"
        validate={[required()]}
        label="resources.pumps.fields.model"
      />
      <TextInput
        source="manufacturer"
        validate={[required()]}
        label="resources.pumps.fields.manufacturer"
      />
    </div>
  );
};
