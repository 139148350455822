import * as R from 'ramda';
import { isNullOrUndefined } from './object-utils';
import { isEmpty } from './string-utils';

export const parseNumber = (value: string | number | null) => {
  if (typeof value === 'number' || value === null) {
    return value;
  }

  return isNullOrUndefined(value) || isEmpty(value) ? null : +value;
};

export const round = (value: number, decimals = 2) =>
  !isNullOrUndefined(value) ? +value.toFixed(decimals) : null;

export const roundUp = (value: number, multiple: number = 1) =>
  Math.ceil(value / multiple) * multiple;

export const roundDown = (value: number, multiple: number = 1) =>
  Math.floor(value / multiple) * multiple;

export const compare = (a: number, b: number) => a - b;

export const getModule = (value: number, max: number, reverse = false) => {
  return reverse ? max - (value % max) - 1 : value % max;
};

export const sum = (a: number, b: number) => a + b;

export const min = (a: number, b: number) => (a < b ? a : b);

export const max = (a: number, b: number) => (a < b ? b : a);

export const isDivisibleBy = R.curry(
  (base: number, value: number) => value % base === 0
);

export const isEven = (value: number) => isDivisibleBy(2, value);

export const percentage = (value: number, base: number, decimals = 2) =>
  base !== 0 ? round((value * 100) / base, decimals) : null;

export const countDecimals = (number: number) => {
  if (Math.floor(number.valueOf()) === number.valueOf()) return 0;
  return number.toString().split('.')[1].length || 0;
};

export const toLocaleString = (value, locale, options = {}) => {
  return value.toLocaleString(locale, options);
};
