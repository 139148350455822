import React from 'react';
import {
  Datagrid,
  List,
  TextField,
  usePermissions,
  useTranslate
} from 'react-admin';
import { EmptyList } from '../../../components/shared/EmptyList';
import { ListHeader } from '../../../components/shared/ListHeader';
import { TableCell } from '../../../components/shared/TableCell';
import { PER_PAGE } from '../../../core/constants/pagination.constants';
import { PrivilegeType } from '../../../core/models/privileges/privilegeType';
import { some } from '../../../core/utils/collection-utils';
import { PumpDeleteButton } from './PumpDeleteButton';
import { PumpEnterButton } from './PumpEnterButton';
import { PumpsListActions } from './PumpsListActions';

export const PumpsList = () => {
  const { permissions } = usePermissions();
  const t = useTranslate();
  const hasDeletePermissions = some([PrivilegeType.MANAGE_PUMPS], permissions);
  return (
    <List
      className="custom-list"
      resource="pumps/paginated"
      actions={<PumpsListActions />}
      empty={
        <EmptyList title={t('shared.list.empty.title')}>
          <PumpsListActions />
        </EmptyList>
      }
      debounce={200}
      perPage={PER_PAGE}
      title={t('resources.pumps.name', 2)}
    >
      <Datagrid
        bulkActionButtons={false}
        className="table-actions"
        style={{ tableLayout: 'fixed' }}
        header={
          <ListHeader
            columns={
              <>
                <TableCell source="pumpName">
                  {t('resources.pumps.fields.pumpName')}
                </TableCell>
                <TableCell source="pumpManufacturer">
                  {t('resources.pumps.fields.manufacturer')}
                </TableCell>
              </>
            }
            deleteColumn={hasDeletePermissions}
            enterColumn={true}
          />
        }
      >
        <TextField source="pumpName" />
        <TextField source="pumpManufacturer" />
        {hasDeletePermissions && <PumpDeleteButton />}
        <PumpEnterButton />
      </Datagrid>
    </List>
  );
};
