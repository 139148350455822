import { defaultTheme } from 'react-admin';

const palette = {
  primary: {
    main: '#006072'
  },
  secondary: {
    main: '#f7f7f7'
  },
  tertiary: {
    main: '#f5f5f5'
  },
  info: {
    light: '#dedede',
    main: '#9b9b9b',
    dark: '#6b6b6b'
  }
};

export const theme = {
  ...defaultTheme,
  palette: palette,
  typography: {
    fontFamily: ['Heebo', 'sans-serif'].join(',')
  },
  sidebar: {
    width: 250,
    closedWidth: 50
  },
  components: {
    ...defaultTheme.components,
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            background: '#CCC',
            color: '#666'
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined' as const
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined' as const
      },
      styleOverrides: {
        root: {
          width: '100%'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF'
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          width: '100%',
          '& .MuiFormLabel-root': {
            top: '-6px'
          }
        },
        inputRoot: {
          padding: '0 9px',
          height: '40px'
        },
        input: {
          height: '23px'
        }
      }
    },
    RaTopToolbar: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
          width: '100%'
        }
      }
    },
    RaToolbar: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
          columnGap: 12
        }
      }
    },
    RaSimpleForm: {
      styleOverrides: {
        root: {
          maxWidth: '620px',
          marginLeft: 'auto',
          marginRight: 'auto'
        }
      }
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          '& .RaSidebar-paperAnchorLeft': {
            backgroundColor: palette.primary.main
          },
          '@media screen and (max-width: 599.95px)': {
            '& .RaSidebar-paperAnchorLeft': {
              //backgroundColor: palette.primary.main,
              padding: '24px 24px 24px 12px'
            },
            '& .MuiMenuItem-root': {
              color: 'rgba(255,255,255,0.7)'
            },
            '& .MuiListItemIcon-root': {
              color: 'rgba(255,255,255,0.7)'
            },
            '& .MuiMenuItem-root.RaMenuItemLink-active': {
              color: '#fff'
            },
            '& .MuiMenuItem-root.RaMenuItemLink-active .MuiListItemIcon-root': {
              color: '#fff'
            },
            '& .logo': {
              marginLeft: '12px',
              marginBottom: '20px'
            }
          }
        }
      }
    },
    RaAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: palette.primary.main,
          color: '#FFF'
        }
      }
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          '@media screen and (max-width: 599.95px)': {
            //maxWidth: '100%',
            //overflow: 'auto',
            '& .RaDatagrid-tableWrapper': {
              maxWidth: '320px',
              overflow: 'auto'
            }
          }
        }
      }
    }
  }
};
