import React from 'react';
import { ResultHeaderItem } from '../../../../core/models/efficiency-tests/result-header-item';
import { toLocaleString } from '../../../../core/utils/number-utils';
import { useLocaleState } from 'react-admin';

interface Props {
  dataFirst: ResultHeaderItem[];
  dataSecond: ResultHeaderItem[];
}
export const EfficiencyTestResultsHeaderTable = (props: Props) => {
  const { dataFirst, dataSecond } = props;
  const [locale] = useLocaleState();
  return (
    <>
      <ul className="column-list column-list-4">
        {dataFirst.map((dataElement) => (
          <li key={crypto.randomUUID()}>
            <span>{dataElement.title}</span>
            <span>{toLocaleString(dataElement.value, locale)}</span>
          </li>
        ))}
      </ul>
      <ul className="column-list column-list-3">
        {dataSecond.map((dataElement) => (
          <li key={crypto.randomUUID()}>
            <span>{dataElement.title}</span>
            <span>{toLocaleString(dataElement.value, locale)}</span>
          </li>
        ))}
      </ul>
    </>
  );
};
