import { Box, Typography } from '@mui/material';
import React from 'react';

export const EmptyList = (props: any) => {
  return (
    <Box textAlign="center" m={1} className="empty">
      <Typography variant="h4" paragraph>
        {props.title}
      </Typography>
      <div>{props.children}</div>
    </Box>
  );
};
