import { filter } from './collection-utils';

export function getErrorMessageCode(error?: any) {
  const errors =
    error && error.response && error.response.data && error.response.data.errors
      ? error.response.data.errors
      : null;

  const globalCode =
    errors && errors.global && errors.global.code ? errors.global.code : null;
  if (globalCode) {
    return globalCode;
  }

  const fieldKey = filter(
    (key: string) => errors.fields[key] && errors.fields[key].code !== null,
    Object.keys(errors.fields)
  )[0] as string;
  const fieldCode =
    errors &&
    errors.fields &&
    errors.fields[fieldKey] &&
    errors.fields[fieldKey].code
      ? errors.fields[fieldKey].code
      : null;
  if (fieldCode) {
    return fieldCode;
  }

  return 'unrecognized_error';
}
