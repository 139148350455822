import React from 'react';
import { Edit, SimpleForm, useNotify, useUpdate } from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';
import { USERS_INDEX_URL } from '../../../core/constants/url.constants';
import { UsersForm } from '../usersForm/UsersForm';
import { UsersFormToolbar } from '../usersForm/UsersFormToolbar';
import { usersFormValidator } from '../usersForm/UsersFormValidator';

export const UsersEdit = (props) => {
  const params = useParams();

  const [update] = useUpdate('users');
  const notify = useNotify();
  const navigate = useNavigate();

  const save = async (data: any) => {
    update(
      'users',
      { id: params.userId, data },
      {
        onSuccess: () => {
          notify('resources.users.notifications.update.success', {
            type: 'success'
          });
          navigate(-1);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Edit
      resource={'users'}
      id={params.userId}
      title="resources.users.fields.editUser"
    >
      <SimpleForm
        onSubmit={save}
        validate={(e) => usersFormValidator(e)}
        toolbar={<UsersFormToolbar route={USERS_INDEX_URL} />}
      >
        <UsersForm />
      </SimpleForm>
    </Edit>
  );
};
