import axiosInstance, { getDefaultRequestConfig } from './base/axios.client';

export async function getCustomers(): Promise<any> {
  return await axiosInstance.get('/customers', getDefaultRequestConfig());
}

export async function createCustomer(customerName: string): Promise<any> {
  return await axiosInstance.post(
    '/customers',
    {
      name: customerName
    },
    getDefaultRequestConfig()
  );
}
