import React from 'react';
import { Create, SimpleForm, useCreate, useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { USERS_INDEX_URL } from '../../../core/constants/url.constants';
import { UsersForm } from '../usersForm/UsersForm';
import { UsersFormToolbar } from '../usersForm/UsersFormToolbar';
import { usersFormValidator } from '../usersForm/UsersFormValidator';

export const UsersCreate = () => {
  const [create] = useCreate('users');
  const notify = useNotify();
  const navigate = useNavigate();

  const save = async (data: any) => {
    create(
      'users',
      { data },
      {
        onSuccess: () => {
          notify('resources.users.notifications.create.success', {
            type: 'success'
          });
          navigate(-1);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Create resource={'users'}>
      <SimpleForm
        onSubmit={save}
        validate={(e) => usersFormValidator(e)}
        toolbar={<UsersFormToolbar route={USERS_INDEX_URL} />}
      >
        <UsersForm />
      </SimpleForm>
    </Create>
  );
};
