import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { PumpDeleteDialog } from './PumpDeleteDialog';

export const PumpDeleteButton = (props: any) => {
  const record: any = useRecordContext(props);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const handleDeletePump = () => setShowDeleteDialog(true);

  return (
    <Box sx={{ width: 60 }}>
      <IconButton onClick={() => handleDeletePump()}>
        <DeleteIcon />
      </IconButton>
      <PumpDeleteDialog
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        id={record.id}
      />
    </Box>
  );
};
