import { TableCell as MuiTableCell } from '@mui/material';
import React from 'react';
import { useListContext } from 'react-admin';

export const TableCell = (props: any) => {
  const { source } = props;
  const { sort, setSort } = useListContext();

  const handleSort = () => {
    if (source && setSort) {
      setSort({ field: source, order: sort.order === 'ASC' ? 'DESC' : 'ASC' });
    }
  };

  return (
    <MuiTableCell
      {...props}
      className={`${props.className} ${source ? 'pointer' : ''}`}
      onClick={handleSort}
    >
      {props.children}
      {sort.field === source && <span>{sort.order === 'ASC' ? '▲' : '▼'}</span>}
    </MuiTableCell>
  );
};
