import React from 'react';
import { Autocomplete, Box, TextField, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';

type Props = {
  fields: any[];
  choices: { id: string; label: string }[];
  source: string;
  errors: any;
  onRowRemoveClick: (i: number) => void;
};

export const AutoCompleteCell = ({
  fields: diameters,
  choices,
  source,
  errors,
  onRowRemoveClick
}: Props) => {
  const t = useTranslate();

  const { register, watch, setValue } = useFormContext();

  return (
    <>
      {diameters.map((field: { id: string; value: string }, i) => (
        <Box key={field.id} className="autocomplete-field" id={field.id}>
          <Button
            onClick={() => onRowRemoveClick(i)}
            disabled={
              watch('flowTestValues').length === 1 && diameters.length === 1
            }
            sx={{
              minWidth: '25px',
              height: '20px',
              padding: '3px',
              margin: '0 5px'
            }}
            tabIndex={-1}
          >
            <CloseIcon fontSize="small" />
          </Button>
          {choices.length > 0 && (
            <Autocomplete
              {...register(`${source}.${i}.value`)}
              sx={{
                height: '22px',
                margin: '0 3px',
                '& .MuiInputBase-root': {
                  height: '22px'
                },
                '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                  height: '16px',
                  fontSize: '13px',
                  margin: '0 auto',
                  padding: '3px',
                  minWidth: '42px'
                },
                '& .MuiOutlinedInput-root': {
                  paddingLeft: '3px',
                  paddingRight: '42px !important'
                },
                '& .MuiFormLabel-filled': {
                  display: 'none'
                },
                '& legend': {
                  display: 'none'
                },
                '& fieldset': {
                  top: 0
                },
                '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
                  right: 0
                },
                '& .MuiAutocomplete-clearIndicator': {
                  marginRight: '-8px'
                },
                '& .MuiAutocomplete-option': {
                  backgroundColor: 'red'
                },
                '& .MuiFormLabel-root': {
                  top: '-13px',
                  fontSize: '13px',
                  display: 'none'
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  display: 'none'
                }
              }}
              renderInput={(field) => (
                <TextField
                  {...field}
                  label={t('resources.efficiency-tests.fields.selection')}
                  placeholder={t('resources.efficiency-tests.fields.selection')}
                  InputLabelProps={{ shrink: false }}
                  error={!!errors?.[i]}
                />
              )}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={(e, value) => {
                if (value && value.id) {
                  setValue(`${source}.${i}.value`, value.id);
                } else {
                  setValue(`${source}.${i}.value`, '');
                }
              }}
              options={choices}
              defaultValue={choices.find((c) => c.id === field.value)}
            />
          )}
        </Box>
      ))}
    </>
  );
};
