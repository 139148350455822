import { TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';

export const ListHeader = (props: any) => {
  return (
    <TableHead>
      <TableRow>
        {props.columns}
        {props.deleteColumn && <TableCell className="cell-action"></TableCell>}
        {props.enterColumn && <TableCell className="cell-action"></TableCell>}
      </TableRow>
    </TableHead>
  );
};
