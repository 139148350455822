import React from 'react';

import { Button, SaveButton, Toolbar } from 'react-admin';
import { useNavigate } from 'react-router-dom';

export const DiametersFormToolbar = ({
  setSection,
  sectionList,
  route
}: any) => {
  const navigate = useNavigate();
  const clearFields = () => {
    if (route) {
      navigate(route);
    }

    if (setSection) {
      setSection(sectionList);
    }
  };

  return (
    <div>
      <Toolbar>
        <Button
          onClick={() => clearFields()}
          label="shared.form.toolbar.actions.cancel"
        ></Button>
        <SaveButton label="shared.form.toolbar.actions.saveChanges" />
      </Toolbar>
    </div>
  );
};
